import { createSlice } from '@reduxjs/toolkit';

export const editPatientSlice = createSlice({
  name: 'editPatient',
  initialState: {
    actualPatient: null,
    isPatientOpen: false,
    isExitPopup: false,
    isSubmittedPopup: false,
  },
  reducers: {
    openPatient: (state, action) => {
      state.actualPatient = action.payload;
      if (state.actualPatient?.dob?.length > 10) state.actualPatient.dob = state.actualPatient.dob.substring(0, 10);
      state.isPatientOpen = true;
    },
    closePatient: (state) => {
      state.actualPatient = null;
      state.isPatientOpen = false;
      state.isExitPopup = false;
      state.isSubmittedPopup = false;
    },
    setFirstName: (state, action) => {
      state.actualPatient.firstname = action.payload;
    },
    setLastName: (state, action) => {
      state.actualPatient.lastname = action.payload;
    },
    setEmail: (state, action) => {
      state.actualPatient.email = action.payload;
    },
    setINS: (state, action) => {
      state.actualPatient.INS = action.payload;
    },
    setPhone: (state, action) => {
      state.actualPatient.phone = action.payload;
    },
    setGender: (state, action) => {
      state.actualPatient.sexe = action.payload;
    },
    setBirthday: (state, action) => {
      state.actualPatient.dob = action.payload;
    },
    setCityINSEE: (state, action) => {
      state.actualPatient.city_INSEE = action.payload;
    },
    setNumeroPatient: (state, action) => {
      state.actualPatient.numero_patient = action.payload;
    },
    setHelperName: (state, action) => {
      state.actualPatient.helper_name = action.payload;
    },
    setComment: (state, action) => {
      state.actualPatient.comment = action.payload;
    },
    setDisableFormsPrivateLinks: (state, action) => {
      state.actualPatient.disable_forms_private_links = action.payload;
    },
    setDisableHelperPhone: (state, action) => {
      state.actualPatient.is_helper_phone = action.payload;
    },
    setDisableNoSmartphone: (state, action) => {
      state.actualPatient.no_smartphone = action.payload;
    },
    setIsExitPopup: (state, action) => {
      state.isExitPopup = action.payload;
    },
    setIsSubmittedPopup: (state, action) => {
      state.isSubmittedPopup = action.payload;
    },
    setGroupe: (state, action) => {
      state.actualPatient.groups = action.payload;
    },
  }
})

export const {
  openPatient,
  closePatient,
  setFirstName,
  setLastName,
  setEmail,
  setCityINSEE,
  setNumeroPatient,
  setPhone,
  setGender,
  setINS,
  setBirthday,
  setIsExitPopup,
  setIsSubmittedPopup,
  setGroupe,
  setDisableFormsPrivateLinks,
  setHelperName,
  setDisableHelperPhone,
  setDisableNoSmartphone,
  setComment,
} = editPatientSlice.actions

export default editPatientSlice.reducer