import { useDispatch } from "react-redux";
import * as React from "react";
import { Grid } from "@mui/material";
import { openSnackbar } from "../../redux/snackbarReducer";
import isValidPhoneNumber from "../../utils/isValidPhoneNumber";
import { afterImportPatient } from "../../redux/ImportPatientReducer";
import formatPhone from "../../utils/format/formatPhone";
import useAppSelector from "../../redux/useAppSelector";

interface PatientInfos {
  phone: string;
  last_name: string;
  first_name: string;
  ins?: string;
  email?: string;
  sexe?: string;
  birth_place?: string;
  birth_date?: string;
  group_id?: string;
  isValidated?: boolean;
  error?: string;
  id?: any;
}

const ImportPatientButton: React.FC<any> = (props) => {
  const { patientList } = props;
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);
  const dispatch = useDispatch();

  const deleteDuplicatedPatient = (tabParsed: Array<PatientInfos>) => {
    let tabParsedTmp: Array<PatientInfos> = [];
    for (const element of tabParsed) {
      const found = tabParsedTmp.findIndex((value) => {
        return (
          value.first_name.toLowerCase() == element.first_name.toLowerCase() &&
          value.last_name.toLowerCase() == element.last_name.toLowerCase() &&
          value.phone == element.phone
        );
      });
      if (found == -1) {
        tabParsedTmp.push(element);
      }
    }
    return tabParsedTmp;
  };

  const getPatientInfosArray = (
    tabParsed: Array<Array<string>>
  ): Array<PatientInfos> => {
    let result: Array<PatientInfos> = [];
    let error: Array<PatientInfos> = [];
    const patientUpdateList: Array<PatientInfos> = [];

    const getIndex = {
      phone: -1,
      first_name: -1,
      last_name: -1,
      ins: -1,
      email: -1,
      sexe: -1,
      birth_place: -1,
      birth_date: -1,
      group_id: -1,
    };

    getIndex.first_name = tabParsed[0].findIndex(
      (e) =>
        e?.toLowerCase()?.includes("prenom") ||
        e?.toLowerCase()?.includes("prénom")
    );
    getIndex.group_id = tabParsed[0].findIndex((e) =>
      e?.toLowerCase()?.includes("groupe")
    );
    getIndex.last_name = tabParsed[0].findIndex(
      (e) =>
        e?.toLowerCase()?.includes("nom") &&
        !e?.toLowerCase()?.includes("prenom") &&
        !e?.toLowerCase()?.includes("prénom")
    );
    getIndex.phone = tabParsed[0].findIndex(
      (e) =>
        e?.toLowerCase()?.includes("tel") || e?.toLowerCase()?.includes("tél")
    );
    getIndex.ins = tabParsed[0].findIndex((e) =>
      e?.toLowerCase()?.includes("ins")
    );
    getIndex.email = tabParsed[0].findIndex(
      (e) =>
        e?.toLowerCase()?.includes("email") ||
        e?.toLowerCase()?.includes("e-mail")
    );
    getIndex.sexe = tabParsed[0].findIndex(
      (e) =>
        e?.toLowerCase()?.includes("sexe") ||
        e?.toLowerCase()?.includes("genre")
    );
    getIndex.birth_place = tabParsed[0].findIndex(
      (e) =>
        e?.toLowerCase()?.includes("lieu") &&
        e?.toLowerCase()?.includes("naissance")
    );
    getIndex.birth_date = tabParsed[0].findIndex(
      (e) =>
        e?.toLowerCase()?.includes("date") &&
        e?.toLowerCase()?.includes("naissance")
    );

    if (
      [getIndex.phone, getIndex.first_name, getIndex.last_name].includes(-1)
    ) {
      dispatch(
        openSnackbar({
          type: "error",
          message:
            "Le fichier CSV doit contenir les colonnes : 'nom', 'prénom' et 'tel'",
          duration: 5000,
        })
      );
      return null;
    }
    const isValidINS = (ins: string): boolean => {
      if (ins?.length === 13 && ins?.toUpperCase().match("[a-bA-B0-9]{13}")) {
        return true;
      }
      return false;
    };
    const isValidPatientInfos = (patientInfos: PatientInfos): boolean => {
      if (!isValidPhoneNumber(patientInfos.phone, ["4", "5", "6", "7", "9"])) {
        patientInfos.error += "Tel ";
        return false;
      }
      return true;
    };

    const isUpdatedPatient = (patientInfos: PatientInfos): boolean => {
      if (!patientList || patientList?.length == 0) return false;
      const convertINS = (ins: string) => {
        if (!ins) {
          return "0000000000000";
        }
        return ins;
      };
      const founded = patientList.find(
        (p) =>
          p?.firstname == patientInfos.first_name &&
          p?.lastname == patientInfos.last_name &&
          p?.phone == patientInfos.phone
      );
      if (!(!founded?.INS && !patientInfos.ins)) {
        if (convertINS(patientInfos.ins) != founded?.INS) {
          return true;
        }
      }
      if (!(!founded?.email && !patientInfos.email)) {
        if (patientInfos.email != founded?.email) {
          return true;
        }
      }
      if (!(!founded?.sexe && !patientInfos?.sexe)) {
        if (patientInfos.sexe != founded?.sexe) {
          return true;
        }
      }
      if ((`["${patientInfos.group_id}"]` != founded?.groups) && !((!founded?.groups || founded?.groups == "[]") && !patientInfos?.group_id)) {
        return true;
      }
      if (patientInfos.birth_place != founded?.city_INSEE) {
        return true;
      }
      if (!(!patientInfos?.birth_date?.split("T")?.[0] && !founded?.dob)) {
        if (
          patientInfos?.birth_date?.split("T")?.[0] != founded?.dob &&
          patientInfos?.birth_date != founded?.dob
        ) {
          return true;
        }
      }
      return false;
    };

    const getId = (patientInfos: PatientInfos): boolean => {
      if (!patientList || patientList?.length == 0) return false;
      const id = patientList.find(
        (p) =>
          p?.firstname == patientInfos.first_name &&
          p?.lastname == patientInfos.last_name &&
          p?.phone == patientInfos.phone
      )?.id;
      if (id) {
        return id;
      }
      return null;
    };

    const isDuplicatedPatient = (patientInfos: PatientInfos): boolean => {
      if (!patientList || patientList?.length == 0) return false;
      if (
        patientList.find(
          (p) =>
            p?.firstname?.toLowerCase() ==
              patientInfos?.first_name?.toLowerCase() &&
            p?.lastname?.toLowerCase() == patientInfos?.last_name?.toLowerCase() &&
            p?.phone == patientInfos?.phone
        )
      ) {
        return true;
      }
      return false;
    };

    let index = 0;
    for (const row of tabParsed) {
      if (index != 0) {
        let patientInfos: PatientInfos = {
          phone: "",
          first_name: "",
          last_name: "",
          error: "",
          ins: "0000000000000",
          email: "",
          sexe: "",
          birth_place: "",
          birth_date: "",
          group_id: "",
          isValidated: false,
        };
        patientInfos.phone = formatPhone(row[getIndex.phone]);
        patientInfos.last_name = row[getIndex.last_name];
        patientInfos.first_name = row[getIndex.first_name];
        if (
          getIndex?.ins != -1 &&
          isValidINS(row?.[getIndex.ins]?.toUpperCase())
        ) {
          patientInfos.ins = row?.[getIndex?.ins]?.toUpperCase();
        }
        if (
          getIndex?.email != -1 &&
          row[getIndex?.email]
            ?.toUpperCase()
            ?.match("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")
        ) {
          patientInfos.email = row[getIndex?.email];
        }
        if (
          getIndex?.sexe != -1 &&
          (row?.[getIndex?.sexe]?.toLowerCase() == "homme" ||
            row?.[getIndex?.sexe]?.toLowerCase() == "femme")
        )
          row[getIndex.sexe].toLowerCase() == "homme"
            ? (patientInfos.sexe = "male")
            : (patientInfos.sexe = "female");
        if (getIndex?.birth_place != -1)
          patientInfos.birth_place = row[getIndex?.birth_place];
        if (getIndex?.group_id != -1)
          patientInfos.group_id = row[getIndex?.group_id];
        if (getIndex?.birth_date != -1) {
          const rowTmp = row?.[getIndex?.birth_date]?.replaceAll("/", "-");
          let dateTmp = null;
          if (rowTmp) dateTmp = rowTmp.split("-");
          if (dateTmp)
            patientInfos.birth_date =
              dateTmp[2] +
              "-" +
              dateTmp[1] +
              "-" +
              dateTmp[0] +
              "T" +
              "00:00:00.00Z";
        }
        if (isDuplicatedPatient(patientInfos)) {
          const idTmp = getId(patientInfos);
          if (
            isUpdatedPatient(patientInfos) &&
            isValidPatientInfos(patientInfos) &&
            idTmp
          ) {
            patientUpdateList.push({ id: idTmp, ...patientInfos });
          }
        } else if (isValidPatientInfos(patientInfos)) {
          patientInfos.isValidated = true;
          result.push(patientInfos);
        } else {
          error.push(patientInfos);
        }
      }
      index++;
    }
    result = deleteDuplicatedPatient(result);
    dispatch(afterImportPatient([result, error, patientUpdateList]));
    return result;
  };

  const handleFile = (e) => {
    const fileReader = new FileReader();
    fileReader.onload = (ev) => {
      let result: any = fileReader?.result;
      let tabSplited = [];
      const tabParsed = [];
      let index = 0;

      result = result?.replaceAll("\r\n", "\n");
      result = result?.replaceAll('"="', "");
      result = result?.replaceAll('"', "");
      if (result?.length > 0) {
        tabSplited = result?.split("\n")?.filter((e) => e != "");
      }
      if (!tabSplited || tabSplited?.length == 0) {
        dispatch(
          openSnackbar({
            message: "Le contenu du fichier est invalide",
            duration: 5000,
            type: "error",
          })
        );
        return;
      }
      const separator = (() => {
        const firstRow = tabSplited[0];
        const comma = [...firstRow].filter((e) => e === ",").length;
        const semicolon = [...firstRow].filter((e) => e === ";").length;
        if (comma > 0 && comma > semicolon) {
          return ",";
        }
        if (semicolon > 0 && semicolon > comma) {
          return ";";
        }
        return null;
      })();
      if (!separator) {
        dispatch(
          openSnackbar({
            message: "Le format CSV n'est pas reconnu",
            duration: 5000,
            type: "error",
          })
        );
        return;
      }
      for (const row of tabSplited) {
        tabParsed.push(row.split(separator));
        index++;
      }
      getPatientInfosArray(tabParsed);
    };
    if (e.target.files[0] != null) {
      if (e.target.files[0].size >= 400000000) {
        dispatch(
          openSnackbar({
            message: "Le fichier est trop lourd",
            duration: 5000,
            type: "error",
          })
        );
      } else if (
        !e?.target?.files?.[0]?.type ||
        ![
          "text/csv",
          "application/vnd.ms-excel",
          "text/plain",
          "text/x-csv",
          "application/csv",
          "application/x-csv",
          "text/csv",
          "text/comma-separated-values",
          "text/x-comma-separated-values",
          "text/tab-separated-values",
        ].includes(e.target.files[0].type)
      ) {
        dispatch(
          openSnackbar({
            message: `Le format du fichier est invalide${
              e?.target?.files?.[0]?.type ? ` (${e.target.files[0].type})` : ""
            }`,
            duration: 5000,
            type: "error",
          })
        );
      } else {
        fileReader.readAsText(e.target.files[0]);
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item display="grid">
        <input
          type="file"
          name={`file-questionnaire`}
          id={`file-patient-list`}
          accept=".csv"
          onChange={(e) => {
            handleFile(e);
          }}
          style={{ display: "none", cursor: "pointer", width: "100%" }}
        />
        <label
          style={{ cursor: "pointer", width: "100%" }}
          htmlFor={`file-patient-list`}
        >
          <div
            style={{
              boxShadow: "1px 3px 5px #0F213467",
              paddingTop: "7px",
              paddingBottom: "7px",
              paddingLeft: "20px",
              maxWidth: "330px",
              paddingRight: "20px",
              borderRadius: "18px",
              opacity: 1,
              background: "#0B243C 0% 0% no-repeat padding-box",
              letterSpacing: "0px",
              color: "#FFFFFF",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "12px",
              fontFamily: "Poppins",
              justifySelf: "flex-end",
            }}
          >
            {windowWidth > 800 ? "Importer depuis un fichier CSV" : "Importer"}
          </div>
        </label>
      </Grid>
      <Grid item display="grid" paddingTop="2px">
        <span
          className="delete_advice_button"
          onClick={() => {
            const lines = [];

            lines.push([
              "Nom",
              "Prenom",
              "Tel patient (*)",
              "Email",
              "Genre",
              "Date de naissance",
              "Lieu de naissance",
              "ID du groupe",
            ]);
            lines.push([
              "Nom 1",
              "Prénom 1",
              `=""0600000001""`,
              "email1@gmail.com",
              "Femme",
              `=""01-01-2000""`,
              "Lieu 1",
              "",
            ]);
            lines.push([
              "Nom 2",
              "Prénom 2",
              `=""0600000002""`,
              "email2@gmail.com",
              "Homme",
              `=""02-02-1998""`,
              "Lieu 2",
              "",
            ]);
            lines.push([
              "Nom 3",
              "Prénom 3",
              `=""0600000003""`,
              "email3@gmail.com",
              "",
              `=""03-03-1980""`,
              "Lieu 3",
              "",
            ]);
            lines.push([
              "Nom 4",
              "Prénom 4",
              `=""0600000004""`,
              "email4@gmail.com",
              "Homme",
              `=""04-04-2012""`,
              "Lieu 4",
              "",
            ]);
            lines.push([
              "Nom 5",
              "Prénom 5",
              `=""0600000005""`,
              "email5@gmail.com",
              "Femme",
              `=""05-05-1999""`,
              "Lieu 5",
              "",
            ]);

            let csv = "";
            for (let i = 0; i < lines.length; i++) {
              for (let j = 0; j < lines[i].length; j++) {
                csv += `"${lines[i][j]}"`;
                if (j === lines[i].length - 1) {
                  csv += "\n";
                } else {
                  csv += ";";
                }
              }
            }

            const universalBOM = "\uFEFF";
            const element = window.document.createElement("a");
            element.setAttribute(
              "href",
              "data:text/csv; charset=utf-8," +
                encodeURIComponent(universalBOM + csv)
            );
            element.setAttribute(
              "download",
              `import-patient-exemple`.replaceAll(" ", "-")
            );
            window.document.body.appendChild(element);
            element.click();

            return csv;
          }}
          style={{ fontSize: "12px", color: "#657273" }}
        >
          Télécharger un exemple
        </span>
      </Grid>
    </Grid>
  );
};

export default ImportPatientButton;
