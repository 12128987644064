import { Grid } from "@mui/material";
import * as React from 'react';

const calcStyle = (style, i) => {
  const newStyle = { ...style };
  if (i === 1) {
    newStyle['fontWeight'] = 'bold';
    newStyle['whiteSpace'] = 'pre';
    return (newStyle)
  } else {
    newStyle['fontWeight'] = 'normal';
    newStyle['whiteSpace'] = 'pre';
  }
  return (newStyle);
}

const BoldOnlyThisCharacters: React.FC<any> = (props) => {
  const saveParagraph = props?.text;
  const paragraph = props?.text?.toLowerCase();
  const searchTerm = props?.characters?.toLowerCase();
  const indexOfFirst = paragraph.indexOf(searchTerm);
  const array = []

  if (!paragraph.includes(searchTerm)) array.push(saveParagraph);
  else {
    array.push(saveParagraph.substring(0, indexOfFirst))
    array.push(saveParagraph.substring(indexOfFirst, indexOfFirst + searchTerm.length))
    array.push(saveParagraph.substring(indexOfFirst + searchTerm.length, paragraph.length))
  }

  return (
    <Grid container direction='row' display='flex'>
      {array.map((element, index) => {
        return (
          <Grid item key={index} display='grid'>
            <span style={calcStyle(props?.style, index)}>
              {array[index]}
            </span>
          </Grid>
        );
      })}
    </Grid>
  )
}

export default BoldOnlyThisCharacters;