import * as React from 'react';
import GoogleAnalyticsClass from '../GoogleAnalyticsClass';
import useAppSelector from '../../redux/useAppSelector';
import { IUser } from '../UserClass';

const useGoogleAnalytics = (): [IUser, GoogleAnalyticsClass] => {
  const user = useAppSelector((x) => x?.userReducer?.user);
  const [googleAnalytics, setGoogleAnalytics] = React.useState<GoogleAnalyticsClass>(null);

  React.useEffect(() => {
    setGoogleAnalytics(new GoogleAnalyticsClass(user));
  }, [user]);

  return ([user, googleAnalytics]);
};

export default useGoogleAnalytics;
