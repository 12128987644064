import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  ArrowDropDown,
  DynamicFormOutlined,
  SvgIconComponent,
  SwapHorizOutlined,
  TextsmsOutlined,
  TipsAndUpdatesOutlined,
  ViewListOutlined,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import { actualContainerType } from "./TutorialsList";
import { useHistory } from "react-router";
import { getActualTimeNumber } from "./NavbarComponent";
import useAppSelector from "../redux/useAppSelector";

interface IMyMenuItem {
  text: string;
  Icon: any;
  dividerEnd: boolean;
  onClick?: any;
  isBold?: boolean;
}

export const MyMenuItem = (props: IMyMenuItem) => {
  const { text, dividerEnd, Icon, onClick, isBold } = props;

  return (
    <>
      <MenuItem onClick={onClick}>
        <Grid container direction="row" display="flex">
          <Grid item display="grid">
            <ListItemIcon>
              <Icon
                fontSize="small"
                style={{ color: "#0B243C", opacity: isBold ? 0.4 : 1 }}
              />
            </ListItemIcon>
          </Grid>
          <Grid item display="grid" alignItems="center">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                color: "#0B243C",
                letterSpacing: "0px",
                opacity: isBold ? 0.4 : 1,
              }}
            >
              {text}
            </span>
          </Grid>
        </Grid>
      </MenuItem>
      {dividerEnd && <Divider />}
    </>
  );
};

export const SelectActualContainer: React.FC<{
  actualContainer: actualContainerType,
  setActualContainerType: React.Dispatch<React.SetStateAction<actualContainerType>>,
}> = (props) => {
  const accesses: {
    forms: string;
    chatbots: string;
    protocoles: string;
    chatbots_lex: string;
  } = useAppSelector((state) => state.authorizationsReducer);
  const user = useAppSelector((x) => x?.userReducer?.user);
  const items = [{
    text: 'Mes Conseils',
    icon: TipsAndUpdatesOutlined,
    isAdmin: false,
    canUse: true,
  }, {
    text: 'Mes Questionnaires',
    icon: DynamicFormOutlined,
    isAdmin: accesses.forms === 'admin',
    canUse: ['admin', 'user'].includes(accesses.forms) && user?.interface && !["8742"].includes(user?.interface),
  }, {
    text: 'Mes Questionnaires Interactifs',
    icon: TextsmsOutlined,
    isAdmin: accesses.chatbots === 'admin',
    canUse: ['admin', 'user'].includes(accesses.chatbots),
  }, {
    text: 'Mes Chatbots',
    icon: TextsmsOutlined,
    isAdmin: accesses.chatbots_lex === 'admin',
    canUse: ['admin', 'user'].includes(accesses.chatbots_lex) && user?.interface && !["8742"].includes(user?.interface),
  }, {
    text: 'Mes Protocoles',
    icon: ViewListOutlined,
    isAdmin: accesses.protocoles === 'admin',
    canUse: ['admin', 'user'].includes(accesses.protocoles) && user?.interface && !["8742"].includes(user?.interface),
  }].filter((x) => x.text !== props.actualContainer)
  .filter((x) => x.canUse === true) as Array<{text: actualContainerType, icon: SvgIconComponent, isAdmin?: boolean, canUse?: boolean}>;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (items?.length == 0) {
    return null;
  }
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip style={{ padding: "0", margin: "0" }} title="Fonctionnalités">
          <IconButton
            onClick={handleClick}
            size="small"
            style={{ margin: "0px", padding: "0px" }}
            aria-controls={open ? "actual-container-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <IconButton
              aria-label="menu"
              onClick={() => {}}
              sx={{ color: "#0B243C", margin: "0px", padding: "0px" }}
            >
              <ArrowDropDown fontSize="medium" />
            </IconButton>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="actual-container-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 9,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {items?.map((item, index) => {
          return (
            <MyMenuItem
              key={index}
              text={item?.text}
              isBold={false}
              onClick={() => {
                props.setActualContainerType(item.text);
                if (item.text === 'Mes Chatbots') {
                  history.push('/home?f=chatbots&tab=meschatbots')
                } else if (item.text === 'Mes Questionnaires') {
                  history.push('/home?f=questionnaires&tab=mesquestionnaires')
                } else if (item.text === 'Mes Questionnaires Interactifs') {
                  history.push('/home?f=questionnaires_interactifs')
                } else if (item.text === 'Mes Conseils') {
                  history.push('/home?f=conseils')
                } else if (item.text === 'Mes Protocoles') {
                  history.push('/home?f=protocoles')
                }
              }}
              dividerEnd={false}
              Icon={item.icon}
            />
          );
        })}
      </Menu>
    </>
  );
}


export default function SelectAccount() {
  const accounts = useAppSelector((state) => state.groupsTokensReducer.accounts);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (accounts?.length == 0) {
    return null;
  }
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip style={{ padding: "0", margin: "0" }} title="Mes comptes">
          <IconButton
            onClick={handleClick}
            size="small"
            style={{ margin: "0px", padding: "0px" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <IconButton
              aria-label="menu"
              onClick={() => {}}
              sx={{ color: "#0B243C", margin: "0px", padding: "0px" }}
            >
              <ArrowDropDown fontSize="medium" />
            </IconButton>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 9,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {accounts?.map((account, index) => {
          return (
            <MyMenuItem
              key={index}
              text={account?.formated_name}
              isBold={false}
              onClick={() => {
                localStorage.setItem(
                  "user",
                  JSON.stringify({
                    token: account?.token,
                    user_id: account?.id,
                    user_email: account?.email,
                    user_nicename: account?.email,
                    user_display_name: account?.first_name + " " + account?.last_name,
                    is_admin: 0,
                  })
                );
                localStorage.setItem("lastConnection", getActualTimeNumber().toString());
                localStorage.setItem("token", account?.token);
                localStorage.setItem("user_id", JSON.stringify(account?.id));
                const url = new URL(window.location.href);
                if (url.hash.includes("?")) {
                  const [baseHash, queryString] = url.hash.split("?");
                  const params = new URLSearchParams(queryString);
            
                  // Suppression du paramètre "token"
                  if (params.has("token")) {
                    params.delete("token");
                    const newHash = `${baseHash}?${params.toString()}`;
                    url.hash = newHash.endsWith("?") ? baseHash : newHash;
                    window.history.replaceState({}, document.title, url.toString());
                  }
                }
            
                // Rechargement de la page
                window.location.reload();
              }}
              dividerEnd={false}
              Icon={SwapHorizOutlined}
            />
          );
        })}
      </Menu>
    </>
  );
}
