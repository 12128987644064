import React, { useState, useEffect } from "react";
import { ReactComponent as IconStar } from "../assets/IconStar.svg";
import { ReactComponent as IconEdit } from "../assets/IconEdit.svg";
import useModal from "./UseModal";
import EventEmitter from "../services/event";
import { AiOutlineStar } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import TutorialDataService from "../services/TutorialService";
import { ReactComponent as IconCreate } from "../assets/IconCreate.svg";
import "../styles/searchComponents.scss";
import FilterContainer from "./MesConseilsBox/FilterContainer/FilterContainer";
import SelectedAdvice from "../utils/SelectedAdvice";
import { CircularProgress, Grid } from "@mui/material";
import CancellablePopup from "./Library/Popups/CancellablePopup";
import ContentAdviceCardContainer from "./MesConseilsBox/Card/ContentCardAdviceContainer";
import PackAdviceContent, {
  fixURLhttps,
} from "./MesConseilsBox/Card/PackAdviceContent";
import NotSelectedCardBody from "./MesConseilsBox/Card/NotSelectedCardBody";
import { updateAdvices, updateFilters } from "../redux/tabsReducer";
import { useDispatch } from "react-redux";
import * as editAdviceReducer from "../redux/editAdviceReducer";
import * as createAdviceReducer from "../redux/createAdviceReducer";
import makeArrayCopy from "../utils/makeCopy";
import useSpecialities from "../utils/hooks/useSpecialities";
import { FullConseilCodeQR, getPackPeriod } from "./LastConseilComponent";
import makeAPIRequest from "../utils/makeAPIRequest";
import { openSnackbar } from "../redux/snackbarReducer";
import useAppSelector from "../redux/useAppSelector";
import { useOpenSnackbar } from "../pages/CreateOrEditChatbotLexPage";
import { DisplayConseil } from "./ConseilComponent";
import { removeParenthesis } from "./MesConseilsBox/TabsContainer/AdminTab/AdminTabContent";
import isImage from "../utils/handle_files/isImage";

export const DisplayFavorite: React.FC<{
  favoriteLoading: any;
  listFavorite: any;
  tutorial: any;
  handleFavorite: any;
}> = (props) => {
  const { favoriteLoading, listFavorite, tutorial, handleFavorite } = props;

  if (favoriteLoading.find((element) => element === tutorial.id)) {
    return (
      <CircularProgress
        style={{ color: "#657273", width: "20px", height: "20px" }}
      />
    );
  } else if (listFavorite.find((element) => element.id === tutorial.id)) {
    return (
      <IconStar
        onClick={() => handleFavorite(tutorial)}
        style={{ cursor: "pointer" }}
      />
    );
  } else {
    return (
      <AiOutlineStar
        onClick={() => handleFavorite(tutorial)}
        style={{ cursor: "pointer" }}
      />
    );
  }
};

const MyIconStar: React.FC<any> = (props) => {
  const { tutorial, handleFavorite, favoriteLoading } = props;

  if (favoriteLoading.includes(tutorial.id)) {
    return (
      <CircularProgress
        style={{ color: "#657273", width: "20px", height: "20px" }}
      />
    );
  }
  return (
    <IconStar
      onClick={() => handleFavorite(tutorial)}
      style={{ cursor: "pointer" }}
    />
  );
};

const MyAiOutlineStar: React.FC<any> = (props) => {
  const { tutorial, handleFavorite, favoriteLoading } = props;

  if (favoriteLoading.includes(tutorial.id)) {
    return (
      <CircularProgress
        style={{ color: "#657273", width: "20px", height: "20px" }}
      />
    );
  }
  return (
    <AiOutlineStar
      onClick={() => handleFavorite(tutorial)}
      style={{ cursor: "pointer" }}
    />
  );
};

const SearchComponents: React.FC<any> = ({
  data,
  searchTexts,
  specialites,
  activeKey,
  filterKeyFavourite,
  setSelectedAdvice,
  filterKey,
  saveFilterKey,
  current,
  user,
  searchIndex,
  setFavoriteLoading,
  favoriteLoading,
  ...props
}: any) => {
  const selectedAdviceObject = new SelectedAdvice();
  const [searchIsLoading, setSearchIsLoading] = React.useState<boolean>(true);
  const { isShowing, toggle } = useModal();
  const [filterKeySearch, setFilterKeySearch] = useState(saveFilterKey);
  const [isDuplicatePopUp, setIsDuplicatePopUp] = useState(null);
  const [idOpen, setIdOpen] = useState({});
  const [searchText, setSearchText] = useState("");
  const [buttonExtendsLoading, setButtonExtendsLoading] =
    React.useState<boolean>(false);
  const [tutorialAdded, setTutorailAdded] = useState([]);
  const [toggleFavorite, setToggleFavorite] = useState([]);
  const [listFavorite, setListFavorite] = useState([]);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [lastConseilAddClick, setLastConseilAddClick] =
    React.useState(undefined);
  const [cannotUseUrlPopup, setCannotUseUrlPopup] = React.useState("");
  const [
    cannotUseQuestionnaireInteractifPopup,
    setCannotUseQuestionnaireInteractifPopup,
  ] = React.useState("");
  const [proArray] = useSpecialities();
  const dispatch = useDispatch();
  const activeTab = useAppSelector((state) => state.tabsReducer.activeTab);
  const tokensReducer = useAppSelector((state) => state.tokensReducer);
  const specialities = useAppSelector((x) => x.languageReducer.displaySpec);
  const snackbarUse = useOpenSnackbar();

  React.useEffect(() => {
    selectedAdviceObject.clear();
  }, []);

  const getTokensCost = () => {
    if ([2, 4, "2", "4"].includes(user?.user?.account_type)) {
      // Premium and administrator
      return 0;
    }
    return tokensReducer?.displayMoreConseilsCost;
  };

  useEffect(() => {
    const onRefreshFavorite = () => {
      TutorialDataService.getAll().then((response: any) => {
        setListFavorite(response.data.data.default_conseils.favourite_conseils);
        setFavoriteLoading([]);
      });
    };

    const OnFilter = (eventData?: any) => {
      const value = props?.tab?.filters;

      if (!value) return;

      const dataSearch = {
        search: props?.tab?.title,
        specialities: value?.spec
          ? specialities.find((x) => x.value == value?.spec)?.specialities
          : "",
        attachement: value?.attchment ? value.attchment : "",
        type: value?.conseil ? value.conseil : "",
      };

      if (value.spec && value.conseil && value.attchment) {
        Spec_Attchment_Conseil(
          {
            spec: value.spec,
            conseil: value.conseil,
            attchment: value.attchment,
          },
          dataSearch
        );
      } else if (value.spec && value.conseil) {
        Spec_Conseil({ spec: value.spec, conseil: value.conseil }, dataSearch);
      } else if (value.spec && value.attchment) {
        Spec_Attchment(
          { spec: value.spec, attchment: value.attchment },
          dataSearch
        );
      } else if (value.conseil && value.attchment) {
        Conseil_Attchment(
          { conseil: value.conseil, attchment: value.attchment },
          dataSearch
        );
      } else if (value.spec) {
        Spec({ spec: value.spec }, dataSearch);
      } else if (value.conseil) {
        Conseil({ conseil: value.conseil }, dataSearch);
      } else if (value.attchment) {
        Attchemnent({ attchment: value.attchment }, dataSearch);
      } else {
        setSearchIsLoading(true);
        TutorialDataService.search(dataSearch).then((response: any) => {
          const result =
            response.data.data.search_conseils_results.search_conseils;
          const listFavoTmp = [];

          for (const advice of result) {
            if (advice?.conseil_favourite_ids) {
              const favorite_ids = JSON.parse(advice?.conseil_favourite_ids);
              for (const favorite_id of favorite_ids) {
                if (favorite_id == user?.getId())
                  listFavoTmp.push({ id: advice?.id });
              }
            }
          }
          setListFavorite(listFavoTmp);
          dispatch(
            updateAdvices({
              index: props?.index,
              total: response.data.data.search_conseils_results.total,
              advices: result,
            })
          );
          setSearchIsLoading(false);
        });
      }
    };

    const onSearch = (eventData) => {
      if (searchText === "") {
        setSearchText(eventData.text);
      }
    };

    const onRefreshAll = () => {
      OnFilter();
    };

    const onCreated = () => {
      OnFilter();
    };

    OnFilter();

    const listenerRefreshAll = EventEmitter.addListener(
      "RefreshAll",
      onRefreshAll
    );
    const listenerRefreshFavorite = EventEmitter.addListener(
      "RefreshFavorite",
      onRefreshFavorite
    );
    const listenerSearched = EventEmitter.addListener("searched", onSearch);
    const listenerFilter = EventEmitter.addListener("filter2", OnFilter);
    const listenerCreated = EventEmitter.addListener("Created", onCreated);

    return () => {
      listenerRefreshAll.remove();
      listenerRefreshFavorite.remove();
      listenerFilter.remove();
      listenerSearched.remove();
      listenerCreated.remove();
    };
  }, [props?.tab?.filters, props?.tab?.title, user, setFavoriteLoading]);

  const reduceSearch = () => {
    dispatch(
      updateAdvices({
        index: props?.index,
        total: props?.tab?.total,
        advices: props?.tab?.advices?.slice(0, -10),
      })
    );
  };

  const extendSearch = () => {
    const value = props?.tab?.filters;

    if (!value) return;

    const dataSearch = {
      search: props?.tab?.title,
      specialities: value?.spec
        ? specialities.find((x) => x.value == value?.spec)?.specialities
        : "",
      attachement: value?.attchment ? value.attchment : "",
      type: value?.conseil ? value.conseil : "",
      start: props?.tab?.advices?.length,
    };

    setButtonExtendsLoading(true);
    TutorialDataService.search(dataSearch)
      .then((response: any) => {
        setButtonExtendsLoading(false);

        if (response?.data?.success === true) {
          const result =
            response?.data?.data.search_conseils_results?.search_conseils;
          dispatch(
            updateAdvices({
              index: props?.index,
              total: response.data.data.search_conseils_results.total,
              advices: [...props?.tab?.advices, ...result],
            })
          );
        } else {
          if (
            response?.data?.data?.[0]?.message ===
            "You don't have enough tokens."
          ) {
            snackbarUse.error("Vous n'avez pas assez jetons");
          } else {
            snackbarUse.error("Impossible d'afficher plus de conseils");
          }
        }
      })
      .catch((err) => {
        setButtonExtendsLoading(false);
        snackbarUse.error("Impossible d'afficher plus de conseils", err);
      });
  };

  const searchRequestWithFilter = (filterFun, search) => {
    setSearchIsLoading(true);
    TutorialDataService.search(search).then((response: any) => {
      const result =
        response?.data?.data.search_conseils_results?.search_conseils;
      const listFavoTmp = [];

      // Update favorites
      for (const advice of result) {
        if (advice?.conseil_favourite_ids) {
          const favorite_ids = JSON.parse(advice?.conseil_favourite_ids);
          for (const favorite_id of favorite_ids) {
            if (favorite_id == user?.getId())
              listFavoTmp.push({ id: advice?.id });
          }
        }
      }
      setListFavorite(listFavoTmp);
      dispatch(
        updateAdvices({
          index: props?.index,
          total: response.data.data.search_conseils_results.total,
          advices: result,
        })
      );
      setSearchIsLoading(false);
    });
  };

  const isValidSpec = (specId, filterSpec) => {
    if (!specialities) {
      return false;
    }
    const founded = specialities.find((x) => x?.value == filterSpec);
    if (!founded) {
      return false;
    }
    if (founded.specialities.includes(specId)) {
      return true;
    }
    return false;
  };

  const Spec_Attchment_Conseil = (data, search) => {
    if (data?.attchment == "photo") {
      searchRequestWithFilter(
        (res) =>
          isValidSpec(res.speciality_id, data.spec) &&
          res.type == data.conseil &&
          res.messages[0][0].attachment != "" &&
          res.messages[0][0].attachment != undefined &&
          res.messages[0][0].attachment != null,
        search
      );
    }
    if (data?.attchment == "vidéo") {
      searchRequestWithFilter(
        (res) =>
          isValidSpec(res.speciality_id, data.spec) &&
          res.type == data.conseil &&
          res.messages[0][0].attachment.includes("mp4") &&
          res.messages[0][0].attachment != undefined &&
          res.messages[0][0].attachment != null,
        search
      );
    }
    if (data?.attchment == "none") {
      searchRequestWithFilter(
        (res) =>
          isValidSpec(res.speciality_id, data.spec) &&
          res.type == data.conseil &&
          res.messages[0][0].attachment == "" &&
          res.messages[0][0].attachment != undefined &&
          res.messages[0][0].attachment != null,
        search
      );
    }
  };

  const Spec_Conseil = (data, search) => {
    searchRequestWithFilter(
      (res) => res.type == data.conseil && res.speciality_id == data.spec,
      search
    );
  };

  const Spec_Attchment = (data, search) => {
    AttachementFormat("speciality_id", data.spec, search);
  };

  const Conseil_Attchment = (data, search) => {
    AttachementFormat("type", data.conseil, search);
  };

  const Conseil = (data, search) => {
    searchRequestWithFilter((res) => res.type == data.conseil, search);
  };

  const Spec = (data, search) => {
    searchRequestWithFilter(
      (res) => isValidSpec(res.speciality_id, data.spec),
      search
    );
  };

  const Attchemnent = (data, search) => {
    if (data?.attchment == "photo") {
      searchRequestWithFilter(
        (res) =>
          res.messages[0][0].attachment != "" &&
          res.messages[0][0].attachment != undefined &&
          res.messages[0][0].attachment != null,
        search
      );
    }
    if (data?.attchment == "vidéo") {
      searchRequestWithFilter(
        (res) =>
          res.messages[0][0].attachment.includes("mp4") &&
          res.messages[0][0].attachment != undefined &&
          res.messages[0][0].attachment != null,
        search
      );
    }
    if (data?.attchment == "none") {
      searchRequestWithFilter(
        (res) =>
          res.messages[0][0].attachment == "" &&
          res.messages[0][0].attachment != undefined &&
          res.messages[0][0].attachment != null,
        search
      );
    }
  };

  const AttachementFormat = (value, compare, search) => {
    if (data?.attchment == "photo") {
      searchRequestWithFilter(
        (res) =>
          res.messages[0][0].attachment != "" &&
          res.messages[0][0].attachment != undefined &&
          res.messages[0][0].attachment != null,
        search
      );
    }
    if (data?.attchment == "vidéo") {
      searchRequestWithFilter(
        (res) =>
          res.messages[0][0].attachment.includes("mp4") &&
          res.messages[0][0].attachment != undefined &&
          res.messages[0][0].attachment != null,
        search
      );
    }
    if (data?.attchment == "none") {
      searchRequestWithFilter(
        (res) =>
          res.messages[0][0].attachment == "" &&
          res.messages[0][0].attachment != undefined &&
          res.messages[0][0].attachment != null,
        search
      );
    }
  };

  const openDiv = (id) => {
    if (idOpen[id] == true) setIdOpen({ ...idOpen, [id]: false });
    else setIdOpen({ ...idOpen, [id]: true });
  };

  const confirmDuplicatePopUp = () => {
    if (!isDuplicatePopUp) return;
    setIsDuplicatePopUp(null);
    window.scrollTo({ top: 0, behavior: "smooth" });
    const tutorial = { ...isDuplicatePopUp };
    const data = {
      type: tutorial.type,
      title: tutorial.title,
      speciality_id: tutorial.speciality_id,
      conseil_profession_id: tutorial.conseil_profession_id,
      add_my_favourites: false,
      messages: [],
    };
    for (const messages of tutorial?.messages) {
      for (const message of messages) {
        data.messages.push({
          attachment: message?.attachment,
          attachment_name: message?.attachment_name,
          url: message?.url,
          short_url: message?.short_url,
          stop_sms: message?.stop_sms,
          content: message?.content,
          hours: message?.hours,
        });
      }
    }
    TutorialDataService.create(data).then((response: any) => {
      EventEmitter.emit("Created", {
        isDuplicate: true,
        filterKeyFavourite: filterKeyFavourite,
        filterKeySearch: filterKeySearch,
        filterKey: filterKey,
        text: tutorial,
      });
    });
  };

  const cancelDuplicatePopUp = () => {
    setIsDuplicatePopUp(null);
  };

  const handleDuplicate = (tutorial) => {
    setIsDuplicatePopUp(tutorial);
  };

  const handleEdit = (tutorial) => {
    if (tutorial.validated != 0) {
      dispatch(createAdviceReducer?.openAdvice({ actualAdvice: tutorial }));
    } else {
      let addToFavorites = false;

      if (
        tutorial?.conseil_favourite_ids?.includes(
          localStorage?.getItem("user_id")
        )
      )
        addToFavorites = true;
      dispatch(
        editAdviceReducer?.openAdvice({
          actualAdvice: tutorial,
          addToFavorites: addToFavorites,
        })
      );
    }
  };

  const handleAdd = async (tutorial, index) => {
    setLastConseilAddClick(tutorial?.id);
    for (const message of tutorial?.messages?.[0]) {
      if (
        message?.questionnaire_interactif_id &&
        message?.is_questionnaire_interactif_owner === false
      ) {
        setCannotUseQuestionnaireInteractifPopup(
          message.questionnaire_interactif_id
        );
        return;
      }
      if (
        message?.url?.includes("/#/q/") &&
        message?.is_form_url_owner === false
      ) {
        setCannotUseUrlPopup(message?.url);
        return;
      }
    }

    selectedAdviceObject.add(tutorial);
    setSelectedAdvice(selectedAdviceObject.getAll());
    EventEmitter.emit("Added", {
      text: tutorial,
    });
    setTutorailAdded({ ...tutorialAdded, [tutorial.id]: true });
  };

  const handleDelete = (tutorial) => {
    selectedAdviceObject.deleteById(tutorial.id);
    setSelectedAdvice(selectedAdviceObject.getAll());
    EventEmitter.emit("Deleted", {
      text: tutorial,
    });
    EventEmitter.emit("DeletedConseil", {
      text: tutorial,
    });
    setTutorailAdded({ ...tutorialAdded, [tutorial.id]: false });
  };

  const displaySpec = (tutorial) => {
    if (!proArray) return "";
    for (const spec of proArray) {
      if (spec?.value === tutorial?.speciality_id) return spec?.label;
    }
  };

  const clearFilters = () => {
    const filtersCleaned = {
      spec: null,
      conseil: null,
      attchment: null,
    };
    dispatch(updateFilters({ index: props?.index, filters: filtersCleaned }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const newKey = { spec: "", conseil: "", attchment: "" };

    Object.assign(newKey, props?.tab?.filters);
    newKey[name] = value;
    dispatch(updateFilters({ index: props?.index, filters: newKey }));
  };

  const tut = (tut) => {
    return tut;
  };

  const handleFavorite = (tutorial) => {
    EventEmitter.emit("Favorite", {
      id: tutorial.id,
      filterKey: filterKey,
      filterKeySearch: filterKeySearch,
      filterKeyFavourite: filterKeyFavourite,
    });
  };

  const makeCopy = (toCopy) => {
    const arr = toCopy?.slice();

    return arr;
  };

  const HandleHours = (tutorial) => {
    return getPackPeriod(tutorial?.messages[0]);
  };

  const displaySearch = (tutorial) => {
    if (tutorial.message) {
      return (
        <div>
          <p>{tutorial.message}</p>
          <div className="conseil-card-title">
            Titre : {tutorial.title}
            {tutorial.isPack ? " Pack oui" : " Pack non"}
          </div>
        </div>
      );
    } else if (tutorial.messages[0]) {
      return (
        <section
          style={{ width: "100%", margin: "0", padding: "0" }}
          className={
            "FlexContainer-text" +
            (idOpen[tutorial.id] == true ? "" : "-collapse")
          }
        >
          {tutorial.type == "pack" && (
            <div
              className={
                "force_" + (idOpen[tutorial.id] == true ? "hide" : "display")
              }
            >
              <button
                className="more-button"
                onClick={() => openDiv(tutorial.id)}
                style={{
                  marginLeft: "20px",
                  marginTop: idOpen[tutorial.id] == true ? "0px" : "-15px",
                }}
              >
                {idOpen[tutorial.id] == true ? "Cacher" : "Afficher"} tous les
                messages
              </button>
            </div>
          )}
          {tutorial.type == "pack" ? (
            makeArrayCopy(tutorial?.messages?.[0])
              ?.sort((elementA, elementB) => elementA?.hours - elementB?.hours)
              ?.map((n, i) => (
                <div
                  style={{ width: "100%" }}
                  key={i}
                  className={
                    idOpen[tutorial.id] == true
                      ? "my_margin"
                      : "conseil-card-pack"
                  }
                >
                  <PackAdviceContent
                    number={i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}
                    attachment={n?.attachment}
                    attachment_name={n?.attachment_name}
                    content={n?.content}
                    url={n?.url}
                    hours={n?.hours}
                    message={n}
                    questionnaire_interactif_id={n?.questionnaire_interactif_id}
                    questionnaire_interactif_title={
                      n?.questionnaire_interactif_title
                    }
                  />
                </div>
              ))
          ) : (
            <div className="content_attachment">
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  padding: "0",
                  margin: "0",
                }}
              >
                {tutorial.messages[0][0].attachment != null &&
                tutorial.messages[0][0].attachment != undefined &&
                tutorial.messages[0][0].attachment != "" &&
                isImage(tutorial.messages[0][0].attachment) ? (
                  <a
                    href={fixURLhttps(tutorial.messages[0][0].attachment)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={fixURLhttps(tutorial.messages[0][0].attachment)}
                      style={{
                        height: 80,
                        width: 80,
                        minWidth: 80,
                        marginRight: "20px",
                      }}
                      alt=""
                    />
                  </a>
                ) : null}
                <ContentAdviceCardContainer
                  url={tutorial?.messages?.[0]?.[0]?.url}
                  content={tutorial?.messages?.[0]?.[0]?.content}
                  questionnaire_interactif_id={
                    tutorial?.messages?.[0]?.[0]?.questionnaire_interactif_id
                  }
                  questionnaire_interactif_nano_id={
                    tutorial?.messages?.[0]?.[0]
                      ?.questionnaire_interactif_nano_id
                  }
                  is_questionnaire_interactif_owner={
                    tutorial?.messages?.[0]?.[0]
                      ?.is_questionnaire_interactif_owner
                  }
                  questionnaire_interactif_title={
                    tutorial?.messages?.[0]?.[0]?.questionnaire_interactif_title
                  }
                  is_form_url_owner={
                    tutorial?.messages?.[0]?.[0]?.is_form_url_owner
                  }
                  display_help_not_owner={true}
                  attachment={fixURLhttps(
                    tutorial?.messages?.[0]?.[0]?.attachment
                  )}
                />
              </div>
              {tutorial.type != "pack"
                ? tutorial.messages[0].map((n, i) => (
                    <p key={i} className="attachment">
                      {n.attachment != "" && n.attachment != null ? (
                        <p style={{ margin: "0" }}>
                          Piece jointe :{" "}
                          <a
                            href={fixURLhttps(n.attachment)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {n.attachment_name == "" ||
                            n.attachment_name == undefined
                              ? n.attachment.split("/")[
                                  n.attachment.split("/").length - 1
                                ]
                              : n.attachment_name}
                          </a>
                        </p>
                      ) : null}
                    </p>
                  ))
                : null}
            </div>
          )}
        </section>
      );
    }
  };

  return (
    <div>
      {cannotUseQuestionnaireInteractifPopup && (
        <CancellablePopup
          firstTitle="Sélection"
          secondTitle="de conseil"
          message="Attention, vous ne pouvez pas envoyer ce questionnaire interactif. Si vous souhaitez le réutiliser, vous devez dupliquer le conseil."
          confirmButtonText="Dupliquer"
          cancelButtonText="Annuler"
          onCancel={() => setCannotUseQuestionnaireInteractifPopup("")}
          onConfirm={() => {
            return;
          }}
        />
      )}
      {cannotUseUrlPopup && (
        <CancellablePopup
          firstTitle="Sélection"
          secondTitle="de conseil"
          message="Attention, vous ne pouvez pas envoyer ce questionnaire. Si vous souhaitez le réutiliser, vous devez dupliquer le conseil."
          confirmButtonText="Dupliquer"
          cancelButtonText="Annuler"
          onCancel={() => setCannotUseUrlPopup("")}
          onConfirm={() => {
            makeAPIRequest(
              "post",
              "/questions/form/create_from_url",
              { url: cannotUseUrlPopup },
              "v3"
            )
              .then((res) => {
                const newFormId = res?.data?.id;
                const toDuplicate = props?.tab?.advices?.find(
                  (x) => x?.id == lastConseilAddClick
                );
                const copyMessages = toDuplicate?.messages?.[0]?.map((x) => {
                  const cp = { ...x };
                  delete cp?.id;
                  if (cp?.url == cannotUseUrlPopup) {
                    cp.url = cp.url.replace(
                      cannotUseUrlPopup?.split("/#/q/")?.[1],
                      newFormId
                    );
                  }
                  return cp;
                });
                const data = {
                  type: toDuplicate?.type,
                  add_my_favourites: false,
                  title: toDuplicate?.title,
                  speciality_id: toDuplicate?.speciality_id,
                  messages: copyMessages,
                };
                TutorialDataService.create(data)
                  .then(() => {
                    EventEmitter.emit("Created", {
                      text: data,
                    });
                    if (data?.add_my_favourites) {
                      EventEmitter.emit("RefreshFavorite");
                    }
                    dispatch(
                      openSnackbar({
                        message:
                          "Le questionnaire a bien été dupliqué, un nouveau conseil a été créé",
                        duration: 5000,
                        type: "success",
                      })
                    );
                    setCannotUseUrlPopup("");
                  })
                  .catch((err) => {
                    dispatch(
                      openSnackbar({
                        message: "Impossible de créer le nouveau conseil",
                        duration: 3000,
                        type: "error",
                      })
                    );
                  });
              })
              .catch((err) => {
                dispatch(
                  openSnackbar({
                    message:
                      err?.response?.data?.error &&
                      err?.response?.data?.error != "Internal Server Error"
                        ? err.response.data.error
                        : `Impossible de trouver ce questionnaire`,
                    duration: 3000,
                    type: "error",
                  })
                );
              });
          }}
        />
      )}
      {isDuplicatePopUp && (
        <CancellablePopup
          firstTitle="Dupliquer"
          secondTitle="ce conseil ?"
          message="Voulez-vous vraiment dupliquer ce conseil ?"
          confirmButtonText="Oui"
          cancelButtonText="Non"
          onCancel={cancelDuplicatePopUp}
          onConfirm={confirmDuplicatePopUp}
        />
      )}
      {activeTab === "searched" + props?.index && (
        <div className="phantom-gradient">
          <FilterContainer
            tab="search"
            clearFilters={clearFilters}
            filterKey={props?.tab?.filters}
            specialities={specialites}
            handleSelectChange={handleSelectChange}
          />
          <ul className="list-group">
            {searchIsLoading && (
              <div
                style={{
                  paddingTop: "5px",
                  paddingBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {props?.tab?.advices &&
              !searchIsLoading &&
              props?.tab?.advices.map((tutorial, index) => (
                <li
                  className={
                    "conseil-card"
                    // + (index === currentIndex ? "active" : "")
                  }
                  key={tutorial.id}
                  style={{ paddingTop: "0" }}
                >
                  <div className="conseil-card-title">
                    {tutorial.type == "pack" ? (
                      <div
                        style={{
                          display: "flex",
                          background: "#5BB9C1 0% 0% no-repeat padding-box",
                          borderRadius: " 0px 0px 10px 10px",
                          width: "51px",
                          height: "28px",
                        }}
                      >
                        <p style={{ margin: "auto", color: "white" }}>Pack</p>
                      </div>
                    ) : (
                      <div style={{ paddingTop: "10px" }}></div>
                    )}
                    {idOpen[tutorial.id] == true ? null : (
                      <Grid container direction="row" wrap="nowrap">
                        <Grid item xs display="grid">
                          <div className="title_detail">
                            {!selectedAdviceObject.isSelectedById(
                              tutorial.id
                            ) && (
                              <p
                                style={{ margin: "0" }}
                                className="titre_Conseil"
                              >
                                {tutorial.title}
                                <br />
                              </p>
                            )}
                            <p
                              style={{ margin: "0" }}
                              className="titre_Conseil"
                            >
                              {removeParenthesis(displaySpec(tutorial))}
                            </p>
                            {tutorial.type == "pack" ? (
                              <p style={{ margin: "0", marginLeft: "0" }}>
                                Détail du pack : {tutorial.messages[0].length}{" "}
                                messages
                                {HandleHours(tutorial)}
                              </p>
                            ) : null}
                            {tutorial.display_name === "1" ? (
                              <p
                                style={{
                                  margin: "0",
                                  marginLeft: "0",
                                  fontSize: "14px",
                                  color: "#6b6b6b",
                                  fontStyle: "italic",
                                }}
                              >
                                Auteur : {tutorial.display_name_str}
                              </p>
                            ) : null}
                            {tutorial.validated == 0
                              ? `en cours de validation`
                              : null}
                          </div>
                        </Grid>
                        <Grid
                          item
                          display="grid"
                          style={{
                            marginLeft: 20,
                            marginRight: 20,
                            marginBottom: 5,
                            marginTop: 5,
                            height: `fit-content`,
                            width: "fit-content",
                            position: "relative",
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="10px"
                          >
                            <Grid item display="grid">
                              <FullConseilCodeQR
                                displayViews={true}
                                conseil={tutorial}
                              />
                            </Grid>
                            {user?.user?.see_credits == 1 && tutorial?.credits && (
                              <Grid item display="grid">
                                <p
                                  style={{
                                    margin: "0",
                                    marginLeft: "0",
                                    fontSize: "12px",
                                    color: "#6b6b6b",
                                  }}
                                >
                                  {tutorial.credits} crédit
                                  {tutorial.credits > 1 ? "s" : ""}
                                </p>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                  {selectedAdviceObject.isSelectedById(tutorial.id) ? (
                    <div className="conseilAded">
                      <div className="cross">
                        <ImCross
                          onClick={() => handleDelete(tutorial)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        className="vl-l"
                        style={{ width: "0px", margin: "auto" }}
                      ></div>
                      <div style={{ margin: "auto", marginLeft: "25px" }}>
                        <p
                          style={{
                            margin: "0px",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: "medium",
                            color: "#23595D",
                          }}
                        >
                          {tutorial.title}
                        </p>
                        <p style={{ margin: "0px" }}>
                          {tutorial.type == "pack" ? (
                            <span>Pack de conseils déjà sélectionné</span>
                          ) : (
                            <span>Conseil déjà sélectionné</span>
                          )}
                        </p>
                      </div>
                      <div
                        className="vl-l"
                        style={{ width: "0px", margin: "auto" }}
                      ></div>
                      <section
                        className="FlexContainer-r"
                        style={{ margin: "auto", marginRight: "-5px" }}
                      >
                        <div>
                          {(tutorial.conseil_favourite_ids.includes(
                            localStorage.getItem("user_id")
                          ) &&
                            toggleFavorite[tutorial.id] == null) ||
                          toggleFavorite[tutorial.id] == true ? (
                            <MyIconStar
                              tutorial={tutorial}
                              handleFavorite={handleFavorite}
                              favoriteLoading={favoriteLoading}
                            />
                          ) : (
                            <MyAiOutlineStar
                              tutorial={tutorial}
                              handleFavorite={handleFavorite}
                              favoriteLoading={favoriteLoading}
                            />
                          )}
                        </div>
                        <button className="icon_button modal-toggle">
                          <IconEdit onClick={() => handleEdit(tutorial)} />
                        </button>
                      </section>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {!selectedAdviceObject.isSelectedById(tutorial.id) && (
                    <NotSelectedCardBody
                      tutorial={tutorial}
                      index={index}
                      handleAdd={handleAdd}
                      handleDuplicate={handleDuplicate}
                      handleEdit={handleEdit}
                      idOpen={idOpen}
                      DisplayConseil={
                        <DisplayConseil
                          idOpen={idOpen}
                          tutorial={tutorial}
                          openDiv={openDiv}
                        />
                      }
                      DisplayFavorite={
                        <DisplayFavorite
                          favoriteLoading={favoriteLoading}
                          handleFavorite={handleFavorite}
                          listFavorite={listFavorite}
                          tutorial={tutorial}
                        />
                      }
                      isShowing={isShowing}
                      favoriteList={listFavorite}
                      toggle={toggle}
                      dataConseil={tut(props?.tab?.advices)}
                      {...props}
                    />
                  )}
                </li>
              ))}
            {(!props?.tab?.advices || props?.tab?.advices?.length === 0) &&
            !searchIsLoading ? (
              <div className="no_results">
                <p style={{ textAlign: "center", marginTop: "20px" }}>
                  «{searchText}» Nous notons que cette recherche n’a donné lieu
                  à aucun résultat, nous vous proposerons prochainement des
                  conseils associés à ces mots-clés. L’équipe CertiPair
                </p>
                <button
                  className="button-create modal-toggle"
                  onClick={() => dispatch(createAdviceReducer?.openAdvice(null))}
                  style={{ lineHeight: "0px", borderRadius: "13px" }}
                >
                  <IconCreate />  Créer un nouveau conseil
                </button>
              </div>
            ) : (
              <div></div>
            )}
            {/* {tab?.advices.length != 0 && search != "" ? (
          <div>
            <h2>Entrer une recherche valide</h2>
          </div>) : (<div></div>)} */}
          </ul>
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing="15px"
          >
            {!searchIsLoading &&
              !buttonExtendsLoading &&
              props?.tab?.advices?.length > 10 && (
                <Grid item display="grid">
                  <button
                    className="more-button"
                    style={{ marginTop: "25px" }}
                    onClick={() => reduceSearch()}
                  >
                    Afficher moins
                  </button>
                </Grid>
              )}
            {!searchIsLoading &&
              !buttonExtendsLoading &&
              props?.tab?.total > props?.tab?.advices?.length && (
                <Grid item display="grid">
                  <button
                    className="more-button"
                    style={{ marginTop: "25px" }}
                    onClick={() => extendSearch()}
                  >
                    Afficher plus{/*({props?.tab?.total - props?.tab?.advices?.length} restants)*/}
                  </button>
                </Grid>
              )}
            {buttonExtendsLoading && (
              <Grid item display="grid" marginTop="35px">
                <CircularProgress
                  style={{ color: "#657273", width: "30px", height: "30px" }}
                />
              </Grid>
            )}
          </Grid>
          {getTokensCost() > 0 &&
            [0, 1, 3, "0", "1", "3"].includes(user?.user?.account_type) && (
              <Grid
                item
                display="grid"
                marginTop="20px"
                justifyContent="center"
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                    color: "rgb(101, 114, 115)",
                    fontSize: "14px",
                  }}
                >
                  * Vous possèdez un compte{" "}
                  {user?.user?.account_type == 0 && "de test"}
                  {user?.user?.account_type == 3 && "de test"}
                  {user?.user?.account_type == 1 && "classique"}, afficher plus
                  de conseils vous coûtera {getTokensCost()} jeton(s)
                </span>
              </Grid>
            )}
        </div>
      )}
    </div>
  );
};

export default SearchComponents;
