import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import makeAPIRequest from "../utils/makeAPIRequest";
import BoxTitle from "../components/Library/Box/BoxTitle";
import useUsersList from "../utils/hooks/useUsersList";
import { formatDateFromTimestamp } from "../components/MonPatientBox/MonPatientBox";
import MySelect from "../components/Library/Select/MySelect";
import { InfoRounded } from "@mui/icons-material";
import FormPopup from "../components/FormPopup/FormPopup";
import FormPopupHeader from "../components/FormPopup/FormPopupHeader";
import formatPhone from "../utils/format/formatPhone";
import FullField from "../components/Fields/FullField";
import useAppSelector from "../redux/useAppSelector";

export const monthConverter = {
  1: "Janvier",
  2: "Février",
  3: "Mars",
  4: "Avril",
  5: "Mai",
  6: "Juin",
  7: "Juillet",
  8: "Août",
  9: "Septembre",
  10: "Octobre",
  11: "Novembre",
  12: "Décembre",
};

const getMonthInfos = (addMonth = 0) => {
  const now = new Date();
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1 + addMonth, 0);
  const firstDay = new Date(now.getFullYear(), now.getMonth() + addMonth, 1);
  const lastDayFixed = new Date(+lastDay + 24 * 60 * 60 * 1000 - 1);

  return {
    begin: +firstDay,
    end: +lastDayFixed,
    label:
      monthConverter[firstDay.getMonth() + 1] + " " + firstDay.getFullYear(),
  };
};

export const createArrayStartAndEnd = (before = 12, after = 0) => {
  const array: Array<{ begin: number; end: number; label: string }> = [];

  for (let i = 1; i < after; i++) {
    array.push(getMonthInfos(i));
  }
  for (let i = 0; i > -1 * before; i--) {
    array.push(getMonthInfos(i));
  }
  return array;
};

const AdminConsommationBox: React.FC<any> = (props) => {
  const {
    usersConsommations,
    isLoading,
    setUsersConsommations,
    monthSelected,
    setMonthSelected,
    usersList,
  } = props;
  const [userIdInfosOpen, setUserIdInfosOpen] = React.useState(null);
  const arrayMonthsInfos = createArrayStartAndEnd();
  const userSelected =
    usersList?.length > 0
      ? usersList?.find((u) => u?.user_id == userIdInfosOpen)
      : null;
  const consommationSelected =
    usersConsommations?.length > 0
      ? usersConsommations?.find((c) => c?.user_id == userIdInfosOpen)
      : null;

  return (
    <>
      {userIdInfosOpen && (
        <FormPopup>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="50px"
          >
            <Grid item display="grid">
              <FormPopupHeader
                firstTitle={"Détails"}
                secondTitle={"consommation"}
                onClose={() => {
                  setUserIdInfosOpen(null);
                }}
              />
            </Grid>
            <Grid item display="grid" paddingTop="25px">
              <span
                style={{
                  color: "0b243c",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  whiteSpace: "pre",
                }}
              >
                {userSelected?.email}
                {"\n"}
                {monthSelected}
                {"\n"}
                {"\n"}
                {`Crédits consommés : ${consommationSelected?.credits_total}`}
                {"\n"}
                {`Nombre d'envois : ${consommationSelected?.consommations?.length}`}
                {"\n"}
                {`Nombre de patients : ${
                  consommationSelected?.consommations?.length > 0
                    ? new Set(
                        consommationSelected.consommations
                          .filter((x) => x?.target)
                          ?.map((y) => formatPhone(y?.target))
                      ).size
                    : 0
                }`}
                {"\n"}
                {`Coût total : ${(
                  consommationSelected?.credits_total * 0.0414
                ).toFixed(3)}€ TTC / ${(
                  consommationSelected?.credits_total * 0.0345
                ).toFixed(3)}€ HT`}
                {"\n"}
                {`Coût moyen par envoi : ${(
                  (consommationSelected?.credits_total /
                    consommationSelected?.consommations?.length) *
                  0.0414
                ).toFixed(3)}€ TTC / ${(
                  (consommationSelected?.credits_total /
                    consommationSelected?.consommations?.length) *
                  0.0345
                ).toFixed(3)}€ HT`}
              </span>
            </Grid>
            <Grid item display="grid" paddingTop="25px">
              <div className="table-grid-consommation-details">
                <span className="table-grid-title">Date</span>
                <span className="table-grid-title">Coût</span>
                <span className="table-grid-title">Destinataire</span>
                <span className="table-grid-title">Message</span>
                {consommationSelected?.consommations?.map((conso) => {
                  return (
                    <>
                      <span
                        className="table-grid-text"
                        style={{
                          color: "0b243c",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {formatDateFromTimestamp(conso?.date)}
                      </span>
                      <span
                        className="table-grid-text"
                        style={{
                          color: "0b243c",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {conso?.credits}
                      </span>
                      <span
                        className="table-grid-text"
                        style={{
                          color: "0b243c",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          whiteSpace: "pre",
                        }}
                      >
                        {formatPhone(conso?.target)}
                      </span>
                      <span
                        className="table-grid-text"
                        style={{
                          color: "0b243c",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {conso?.message}
                      </span>
                    </>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </FormPopup>
      )}
      <Grid
        container
        direction="column"
        wrap="nowrap"
        display="flex"
        paddingLeft="41px"
        paddingTop="26px"
        paddingRight="41px"
        paddingBottom="60px"
        minWidth="320px"
        style={{
          background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
          borderRadius: "24px",
        }}
      >
        <Grid item display="grid">
          <Grid container display="flex" direction="column" wrap="nowrap">
            <Grid item display="grid">
              <BoxTitle first="Consommation" second="de crédits" />
            </Grid>
            <Grid item display="grid" paddingTop="30px">
              <Grid
                container
                direction="row"
                alignItems="center"
                gap="15px"
                wrap="nowrap"
              >
                <Grid item display="grid">
                  <FullField isMandatory={false} title="Période :">
                    <></>
                  </FullField>
                </Grid>
                <Grid item display="grid">
                  <div style={{ width: "250px" }}>
                    <MySelect
                      isClearable={false}
                      placeholder={"Sélectionnez une période"}
                      options={arrayMonthsInfos.map((info) => {
                        return {
                          value: info.label,
                          label: info.label,
                        };
                      })}
                      onChange={(e) => {
                        setMonthSelected(e?.value);
                      }}
                      value={{ value: monthSelected, label: monthSelected }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {usersConsommations?.length > 0 &&
              Array.isArray(usersConsommations) && (
                <Grid item display="grid" paddingTop="20px">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#0B243C",
                      textAlign: "center",
                    }}
                  >
                    {`${usersConsommations.reduce(
                      (acc, data) => acc + data?.credits_total,
                      0
                    )} crédits consommés pour un coût total de ${(
                      usersConsommations.reduce(
                        (acc, data) => acc + data?.credits_total,
                        0
                      ) * 0.0414
                    ).toFixed(3)}€ TTC / ${(
                      usersConsommations.reduce(
                        (acc, data) => acc + data?.credits_total,
                        0
                      ) * 0.0345
                    ).toFixed(3)}€ HT`}
                  </span>
                </Grid>
              )}
            <Grid item display="grid" paddingTop="30px">
              {!isLoading ? (
                <>
                  {usersConsommations?.length > 0 ? (
                    <div
                      className="table-grid-consommation"
                      style={{ overflow: "auto" }}
                    >
                      <span className="table-grid-title">Utilisateur</span>
                      <span className="table-grid-title">
                        Crédits consommés
                      </span>
                      <span className="table-grid-title">Coût</span>
                      <span className="table-grid-title"> </span>
                      {Array.isArray(usersConsommations) &&
                        usersConsommations.map((consommation, index) => {
                          return (
                            <>
                              <span
                                className="table-grid-text"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {(() => {
                                  if (!usersList) {
                                    return "";
                                  }
                                  const userFounded = usersList?.find(
                                    (user) =>
                                      user?.user_id == consommation?.user_id
                                  );
                                  if (userFounded) {
                                    return userFounded?.email;
                                  }
                                  return "";
                                })()}
                              </span>
                              <span
                                className="table-grid-text"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {consommation?.credits_total
                                  ? consommation?.credits_total
                                  : 0}
                              </span>
                              <span
                                className="table-grid-text"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {consommation?.credits_total
                                  ? `${(
                                      consommation?.credits_total * 0.0414
                                    ).toFixed(3)}€ TTC\n${(
                                      consommation?.credits_total * 0.0345
                                    ).toFixed(3)}€ HT`
                                  : (0).toFixed(3)}
                              </span>
                              <div className="table-grid-text">
                                <Tooltip title="Détails">
                                  <IconButton
                                    onClick={() =>
                                      setUserIdInfosOpen(consommation?.user_id)
                                    }
                                    style={{
                                      margin: "0",
                                      padding: "0",
                                    }}
                                  >
                                    <InfoRounded
                                      fontSize="small"
                                      style={{ color: "#0b243c" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  ) : (
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#0b243c",
                        fontFamily: "Poppins",
                      }}
                    >
                      Aucune consommation trouvée
                    </span>
                  )}
                </>
              ) : (
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const AdminConsommationPage: React.FC<any> = (props) => {
  const arrayMonthsInfos = createArrayStartAndEnd();
  const { usersList } = useUsersList();
  const [isFirstRequest, setIsFirstRequest] = React.useState(true);
  const windowWidth = useAppSelector((state) => state.windowReducer.windowWidth);
  const [isLoading, setIsLoading] = React.useState(false);
  const [usersConsommations, setUsersConsommations] = React.useState({});
  const [monthSelected, setMonthSelected] = React.useState(
    arrayMonthsInfos[0].label
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      makeAPIRequest(
        "post",
        "/users/consommation/list",
        {
          begin: arrayMonthsInfos.find((info) => info.label === monthSelected)
            .begin,
          end: arrayMonthsInfos.find((info) => info.label === monthSelected)
            .end,
        },
        "v3"
      )
        .then((res) => {
          setUsersConsommations(res?.data);
        })
        .catch((err) => console.error(err));
    }, 60000);
    return () => clearInterval(interval);
  }, [monthSelected]);

  React.useEffect(() => {
    if (isFirstRequest) {
      setIsLoading(true);
    }
    makeAPIRequest(
      "post",
      "/users/consommation/list",
      {
        begin: arrayMonthsInfos.find((info) => info.label === monthSelected)
          .begin,
        end: arrayMonthsInfos.find((info) => info.label === monthSelected).end,
      },
      "v3"
    )
      .then((res) => {
        setUsersConsommations(res?.data);
        setIsFirstRequest(false);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, [monthSelected, isFirstRequest]);

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="admin_consommation" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="admin_consommation" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
          minWidth={700}
        >
          <AdminConsommationBox
            usersList={usersList}
            monthSelected={monthSelected}
            setMonthSelected={setMonthSelected}
            usersConsommations={usersConsommations}
            setUsersConsommations={setUsersConsommations}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminConsommationPage;
