import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import BoxTitle from "../components/Library/Box/BoxTitle";
import makeAPIRequest from "../utils/makeAPIRequest";
import useAppSelector from "../redux/useAppSelector";
import { useOpenSnackbar } from "./CreateOrEditChatbotLexPage";
import { DrawMyFacture, FactureInvoicePlus } from "./AdminOthersStatisticsPage";
import { GenericSelect } from "./QuestionnairePage";
import { MoreOrLessButtons } from "./ChatbotLexPage";
import { createArrayStartAndEnd } from "./AdminConsommationPage";

const statusOptions = [
  {
    label: "Validé",
    value: "DONE",
  },
  {
    label: "Brouillon",
    value: "DRAFT",
  },
  {
    label: "Envoyé",
    value: "SENDED",
  },
];

const paiementOptions = [
  {
    label: "Payé",
    value: "PAID",
  },
  {
    label: "Non payé",
    value: "UNPAID",
  },
];

const AdminFacturesBox: React.FC = () => {
  const [factures, setFactures] =
    React.useState<FactureInvoicePlus[]>(undefined);
  const [maxItem, setMaxItem] = React.useState<number>(10);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const snackbarOpenUse = useOpenSnackbar();
  const [filter, setFilter] = React.useState<{
    status: "DONE" | "DRAFT" | "SENDED";
    paiement: "PAID" | "UNPAID";
    customer: string;
    period: string;
  }>({
    status: undefined,
    paiement: undefined,
    customer: undefined,
    period: undefined,
  });

  const monthsList = createArrayStartAndEnd(36, 0);
  const monthsOptions = monthsList.map((x) => {
    return { label: x?.label, value: x?.label };
  });

  const customerOptions = factures?.length > 0
  ? factures
      .filter((y) => y?.customer?.name && y?.customer?.user_id) // Filtrer les factures valides
      .reduce((uniqueCustomers, current) => {
        // Vérifier si user_id est déjà dans le tableau
        if (!uniqueCustomers.some((item) => item.value === current.customer.user_id)) {
          uniqueCustomers.push({
            label: current.customer.name,
            value: current.customer.user_id,
          });
        }
        return uniqueCustomers;
      }, [])
  : [];

  const filteredFactures = (() => {
    if (!factures) {
      return undefined;
    }
    let facturesTmp = [...factures];
    if (filter?.status && filter?.status !== "SENDED") {
      facturesTmp = facturesTmp.filter((x) => !x?.sended_date && x?.status === filter.status);
    }
    if (filter?.status === "SENDED") {
      facturesTmp = facturesTmp.filter((x) => x?.sended_date);
    }
    if (filter?.paiement) {
      facturesTmp = facturesTmp.filter(
        (x) => x?.paid_status === filter.paiement
      );
    }
    if (filter?.customer) {
      facturesTmp = facturesTmp.filter(
        (x) => x?.customer?.user_id === filter.customer
      );
    }
    if (filter?.period) {
      facturesTmp = facturesTmp.filter(
        (x) => x?.lines?.[0]?.title?.includes(filter.period)
      );
    }
    return facturesTmp;
  })();

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", "/facturations/all", null, "v3")
      .then((res) => {
        setFactures(res?.data);
      })
      .catch((err) => {
        snackbarOpenUse.error("Impossible de récupérer les factures", err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        display="flex"
        paddingLeft="41px"
        paddingTop="26px"
        paddingRight="41px"
        paddingBottom="60px"
        minWidth="320px"
        style={{
          background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
          borderRadius: "24px",
        }}
      >
        <Grid item display="grid">
          <Grid container display="flex" direction="column" wrap="nowrap">
            <Grid item display="grid">
              <BoxTitle first="Gérer" second="les factures" />
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing="10px"
                paddingTop="20px"
              >
                <Grid item display="grid">
                  <span>Filtrer par :</span>
                </Grid>
                <Grid item display="grid" width="200px">
                  <GenericSelect
                    fieldTitle={""}
                    options={customerOptions}
                    value={customerOptions?.find(
                      (x) => x?.value == filter?.customer
                    )}
                    maxMenuHeight={"400px"}
                    id={"filter-customer-input"}
                    isClearable={true}
                    onChange={(e: any) => {
                      setFilter((x) => {
                        return {
                          ...x,
                          customer: e?.value ? e.value : undefined,
                        };
                      });
                    }}
                    placeholder="Utilisateur"
                    isMandatory={false}
                  />
                </Grid>
                <Grid item display="grid" width="200px">
                  <GenericSelect
                    fieldTitle={""}
                    options={monthsOptions}
                    value={monthsOptions?.find(
                      (x) => x?.value == filter?.period
                    )}
                    maxMenuHeight={"400px"}
                    id={"filter-period-input"}
                    isClearable={true}
                    onChange={(e: any) => {
                      setFilter((x) => {
                        return {
                          ...x,
                          period: e?.value ? e.value : undefined,
                        };
                      });
                    }}
                    placeholder="Période"
                    isMandatory={false}
                  />
                </Grid>
                <Grid item display="grid" width="150px">
                  <GenericSelect
                    fieldTitle={""}
                    options={statusOptions}
                    value={statusOptions?.find(
                      (x) => x?.value == filter?.status
                    )}
                    maxMenuHeight={"400px"}
                    id={"filter-status-input"}
                    isClearable={true}
                    onChange={(e: any) => {
                      setFilter((x) => {
                        return { ...x, status: e?.value ? e.value : undefined };
                      });
                    }}
                    placeholder="Status"
                    isMandatory={false}
                  />
                </Grid>
                <Grid item display="grid" width="150px">
                  <GenericSelect
                    fieldTitle={""}
                    options={paiementOptions}
                    value={paiementOptions?.find(
                      (x) => x?.value == filter?.paiement
                    )}
                    maxMenuHeight={"400px"}
                    id={"filter-paiement-input"}
                    isClearable={true}
                    onChange={(e: any) => {
                      setFilter((x) => {
                        return {
                          ...x,
                          paiement: e?.value ? e.value : undefined,
                        };
                      });
                    }}
                    placeholder="Paiement"
                    isMandatory={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              display="grid"
              paddingTop="20px"
              style={{
                fontSize: "16px",
                color: "#0B243C",
                whiteSpace: "pre-wrap",
                fontFamily: "Poppins",
              }}
            >
              {!isLoading ? (
                <>
                  {filteredFactures ? (
                    <Grid container direction="column" wrap="nowrap" gap="10px">
                      {filteredFactures?.length == 0 && (
                        <Grid item display="grid">
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#0B243C",
                              fontFamily: "Poppins",
                            }}
                          >{`Aucune facture n'a été trouvée.`}</span>
                        </Grid>
                      )}
                      {filteredFactures.slice(0, maxItem).map((facture) => {
                        return (
                          <Grid item display="grid" key={facture?.id}>
                            <DrawMyFacture
                              facture={facture}
                              onFactureUpdate={(newFacture) => {
                                setFactures((state) => {
                                  return state.map((item) => {
                                    if (item?.id === newFacture?.id) {
                                      return newFacture;
                                    }
                                    return item;
                                  });
                                });
                              }}
                            />
                          </Grid>
                        );
                      })}
                      {filteredFactures?.length > 0 && (
                        <MoreOrLessButtons
                          maxItem={maxItem}
                          setMaxItem={setMaxItem}
                          items={filteredFactures}
                          marginTop="0px"
                        />
                      )}
                    </Grid>
                  ) : (
                    <span>Il n'y a aucune facture à afficher.</span>
                  )}
                </>
              ) : (
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const AdminFacturesPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="admin_factures" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="admin_factures" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
          minWidth={700}
        >
          <AdminFacturesBox />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminFacturesPage;
