import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import makeAPIRequest from "../utils/makeAPIRequest";
import BoxTitle from "../components/Library/Box/BoxTitle";
import Creatable from "react-select/creatable";
import useUsersList from "../utils/hooks/useUsersList";
import { formatDateFromTimestamp } from "../components/MonPatientBox/MonPatientBox";
import { InfoRounded } from "@mui/icons-material";
import formatPhone from "../utils/format/formatPhone";
import { formatHours } from "../components/MesConseilsBox/Card/PackAdviceContent";
import OrangeCheckBox from "../components/Fields/CheckBox/OrangeCheckBox";
import useAppSelector from "../redux/useAppSelector";

const AdminActivityBox: React.FC<any> = (props) => {
  const {
    activities,
    isLoading,
    setActivities,
    activitiesSelected,
    setActivitiesSelected,
    displayOnlyUserActions,
    setDisplayOnlyUserActions,
    usersList,
    setUsersSelected,
    usersSelected,
  } = props;

  const [displayActivities, setDisplayActivities] = React.useState<
    Array<{
      date: number;
      user_id: string;
      type: string;
    }>
  >([]);

  const listActivities = {
    // Création
    patients_imported: "Importation de patients (CSV)",
    patient_created: "Création patient",
    conseil_created: "Création conseil",
    form_created: "Création questionnaire",
    chatbot_created: "Création questionnaire interactif",
    user_register: "Création de compte",

    // Update
    patient_updated: "Mise à jour patient",
    patients_updated: "Mise à jour de plusieurs patients (CSV)",

    // Programmation
    free_text_schedule: "Programmation texte libre",
    conseil_schedule: "Programmation conseil",
    schedule_chatbot: "Programmation chatbot",
    schedule_form: "Programmation d'un questionnaire",
    schedule_questionnaire_interactif:
      "Programmation d'un questionnaire interactif",

    // Envoi d'SMS
    received_form_response: "Réponse à un questionnaire",
    sms_sended_conseil: "Envoi SMS (conseil)",
    sms_sended_chatbot: "Envoi SMS (questionnaire interactif)",
    sms_sended_form: "Envoi SMS (questionnaire)",
    sms_sended_alert_form: "Envoi SMS (alerte questionnaire)",
    sms_notification_form: "Envoi SMS (notification questionnaire)",

    // Envoi d'email
    email_sended_after_email_response: "Envoi email (réponse questionnaire)",

    // Autres informations
    display_more_conseils: "Affiche plus de conseils",
    blocked_by_stop_sms: "Envoi SMS bloqué par STOP SMS",
    access_to_accessible_account: "Token d'un compte accessible",
  };

  const isInDisplayActivitiesList = (activity: any) => {
    if (
      displayActivities.find(
        (act) =>
          act.date === activity?.date &&
          act.type === activity?.type &&
          act.user_id === activity?.user_id
      )
    ) {
      return true;
    }
    return false;
  };

  const indexFromDisplayActivitiesList = (activity: any) => {
    const index = displayActivities.findIndex(
      (act) =>
        act.date === activity?.date &&
        act.type === activity?.type &&
        act.user_id === activity?.user_id
    );
    return index;
  };

  const formatActivityText = (activity: any) => {
    if (
      !activity?.type ||
      !Object.keys(listActivities).includes(activity?.type)
    ) {
      return "";
    }
    if (activity.type === "access_to_accessible_account") {
      let text = "Token d'un compte accessible";
      if (isInDisplayActivitiesList(activity)) {
        if (activity?.accountId) {
          text += `\nID utilisateur : ${activity.accountId}`;
        }
        if (activity?.accountEmail) {
          text += `\nEmail utilisateur : ${activity.accountEmail}`;
        }
        if (activity?.ip) {
          text += `\nAdresse IP : ${activity.ip}`;
        }
        if (activity?.hostname) {
          text += `\nNom de domaine : ${activity.hostname}`;
        }
        if (activity?.expiresIn) {
          text += `\nDurée d'accès : ${activity.expiresIn}`;
        }
      }
      return text;
    }
    if (activity.type === "received_form_response") {
      let text = "Réponse à un questionnaire";
      if (isInDisplayActivitiesList(activity)) {
        if (activity?.formTitle) {
          text += `\nTitre : ${activity.formTitle}`;
        }
        if (activity?.formUrl) {
          text += `\nURL : ${activity.formUrl}`;
        }
        text += `\nLe patient a reçu un SMS : ${
          activity?.sendedSMSToPatient ? "Oui" : "Non"
        }`;
        text += `\nL'utilisateur a reçu un mail : ${
          activity?.sendedEmailToUser ? "Oui" : "Non"
        }`;
      }
      return text;
    } else if (activity.type === "schedule_chatbot") {
      const addPatientsString =
        activity?.patients?.length > 1
          ? ` (${activity?.patients?.length} patients)`
          : ` (1 patient)`;
      let text = `Programmation d'un chatbot`;
      text += addPatientsString;
      if (isInDisplayActivitiesList(activity)) {
        if (activity?.title) {
          text += `\nTitre : ${activity.title}`;
        }
        if (activity?.trigger) {
          text += `\nDate de déclenchement : ${formatDateFromTimestamp(
            activity.trigger
          )}`;
        }
        if (activity?.patients?.length > 0) {
          text += `\nID ${
            activity?.patients?.length > 1 ? "des patients" : "du patient"
          } : ${activity.patients.join(", ")}`;
        }
        if (activity?.inactiveTimeStop) {
          text += `\nTemps d'inactivité avant arrêt : ${activity.inactiveTimeStop}min`;
        }
        if (activity?.inactiveTimeStop) {
          text += `\nNombre de réponses avant arrêt : ${activity.tooManyResponseStop}`;
        }
      }
      return text;
    } else if (activity.type === "schedule_questionnaire_interactif") {
      const addPatientsString =
        activity?.patients?.length > 1
          ? ` (${activity?.patients?.length} patients)`
          : ` (1 patient)`;
      let text = `Programmation d'un questionnaire interactif`;
      text += addPatientsString;
      if (isInDisplayActivitiesList(activity)) {
        if (activity?.title) {
          text += `\nTitre : ${activity.title}`;
        }
        if (activity?.trigger) {
          text += `\nDate de déclenchement : ${formatDateFromTimestamp(
            activity.trigger
          )}`;
        }
        if (activity?.patients?.length > 0) {
          text += `\nID ${
            activity?.patients?.length > 1 ? "des patients" : "du patient"
          } : ${activity.patients.join(", ")}`;
        }
      }
      return text;
    } else if (activity.type === "schedule_form") {
      const addPatientsString =
        activity?.patients?.length > 1
          ? ` (${activity?.patients?.length} patients)`
          : ` (1 patient)`;
      let text = `Programmation d'un questionnaire`;
      text += addPatientsString;
      if (isInDisplayActivitiesList(activity)) {
        if (activity?.title) {
          text += `\nTitre : ${activity.title}`;
        }
        if (activity?.trigger) {
          text += `\nDate de déclenchement : ${formatDateFromTimestamp(
            activity.trigger
          )}`;
        }
        if (activity?.smsContent) {
          text += `\nType d'envoi : ${
            activity?.sendType === "sms" ? "SMS" : "Email"
          }`;
        }
        if (activity?.emailSubject && activity?.sendType === "email") {
          text += `\nObjet : ${activity.emailSubject}`;
        }
        if (activity?.smsContent) {
          text += `\nContenu : ${activity.smsContent}`;
        }
        if (activity?.patients?.length > 0) {
          text += `\nID ${
            activity?.patients?.length > 1 ? "des patients" : "du patient"
          } : ${activity.patients.join(", ")}`;
        }
        text += `\nRecevoir un SMS si aucune réponse : ${
          activity?.receiveSMSIfNoResponse
            ? `Oui${
                activity?.receiveSMSIfNoResponseDays
                  ? ` (après ${activity.receiveSMSIfNoResponseDays} jours)`
                  : ""
              }`
            : "Non"
        }`;
        text += `\nRecevoir un SMS après réponse du patient : ${
          activity?.receiveSMSAfterPatientResponse ? "Oui" : "Non"
        }`;
      }
      return text;
    } else if (activity.type === "patient_created")
      return "Création d'un patient";
    else if (activity.type === "patient_updated")
      return "Mise à jour d'un patient";
    else if (activity.type === "patients_updated" && activity?.amount)
      return `Mise à jour de ${activity?.amount} patient${
        activity?.amount > 1 ? "s" : ""
      }`;
    else if (activity.type === "conseil_created")
      return "Création d'un conseil";
    else if (activity.type === "patients_imported" && activity?.amount)
      return `Importation de ${activity?.amount} patient${
        activity?.amount > 1 ? "s" : ""
      }`;
    else if (activity.type === "free_text_schedule") {
      const addPatientsString =
        activity?.targets?.length > 1
          ? ` (${activity?.targets?.length} patients)`
          : ` (1 patient)`;
      let text = `Programmation d'un texte libre`;
      text += addPatientsString;
      if (isInDisplayActivitiesList(activity)) {
        text += `\nContenu : ${activity?.content}`;
        text += `\nDestinataire${activity?.targets?.length > 1 ? "s" : ""} : ${
          activity?.targets
            ? activity.targets?.map((x) => formatPhone(x?.target)).join(", ")
            : formatPhone(activity?.target)
        }`;
        if (activity?.hours == "0" || activity?.hours == null)
          text += "\nDéclenchement : Immédiat";
        else {
          text += `\nDéclenchement : Dans ${formatHours(activity?.hours)}`;
        }
      }
      return text;
    } else if (activity.type === "conseil_schedule") {
      const packs = activity?.conseils?.filter(
        (conseil) => conseil?.type === "pack"
      );
      const simples = activity?.conseils?.filter(
        (conseil) => conseil?.type === "simple"
      );
      let text = "";
      const addPatientsString =
        activity?.targets?.length > 1
          ? ` (${activity?.targets?.length} patients)`
          : ` (1 patient)`;
      if (packs?.length > 0 && simples.length > 0)
        text = `Programmation ${
          packs.length === 1 ? "d'un pack" : `de ${packs.length} packs`
        } et ${simples.length} conseil${simples.length > 1 ? "s" : ""} unique${
          simples.length > 1 ? "s" : ""
        }`;
      else if (packs?.length > 0)
        text =
          packs.length > 1
            ? `Programmation de ${packs.length} packs de conseils`
            : `Programmation d'un seul pack de conseils`;
      else if (simples?.length > 0)
        text =
          simples.length > 1
            ? `Programmation de ${simples.length} conseils uniques`
            : `Programmation d'un seul conseil unique`;
      else return listActivities[activity.type];
      text += addPatientsString;
      if (isInDisplayActivitiesList(activity)) {
        const titles = activity?.conseils?.map((conseil) => conseil?.title);
        if (titles.length > 0) {
          text += `\nTitre${titles.length > 1 ? "s" : ""} : ${titles.join(
            ", "
          )}`;
        }
        text += `\nDestinataire${activity?.targets?.length > 1 ? "s" : ""} : ${
          activity?.targets
            ? activity.targets?.map((x) => formatPhone(x?.target)).join(", ")
            : formatPhone(activity?.target)
        }`;
        if (activity?.hours == "0" || activity?.hours == null) {
          text += "\nDéclenchement : Immédiat";
        } else {
          text += `\nDéclenchement : Dans ${formatHours(activity?.hours)}`;
        }
      }
      return text;
    }
    return listActivities[activity.type];
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      minWidth="320px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <Grid container display="flex" direction="column" wrap="nowrap">
          <Grid item display="grid">
            <BoxTitle first="Historique" second="des activités" />
          </Grid>
          <Grid item display="grid" paddingTop="25px">
            <Grid container direction="column" gap="5px" wrap="nowrap">
              <Grid item display="grid">
                <span className="filter-by-text">Filtrer par :</span>
              </Grid>
              <Grid item display="grid">
                <div style={{ width: "600px" }}>
                  <Creatable
                    noOptionsMessage={() => "Aucune option"}
                    formatCreateLabel={(userInput) => `...`}
                    isMulti={true}
                    options={(() => {
                      const optionsTmp = [];
                      for (const user of usersList) {
                        if (
                          user?.email &&
                          user?.token &&
                          user.email?.length > 0
                        ) {
                          optionsTmp.push({
                            label: user?.email,
                            value: user?.user_id,
                          });
                        }
                      }
                      return optionsTmp;
                    })()}
                    value={usersSelected}
                    placeholder="Utilisateurs"
                    onChange={(e) => {
                      setUsersSelected(
                        e?.filter((opt) =>
                          usersList?.find((user) => user?.email == opt?.label)
                        )
                      );
                    }}
                  />
                </div>
              </Grid>
              <Grid item display="grid">
                <div style={{ width: "600px" }}>
                  <Creatable
                    noOptionsMessage={() => "Aucune option"}
                    formatCreateLabel={() => `...`}
                    isMulti={true}
                    options={(() => {
                      const optionsTmp = [];
                      const listActivitiesAuto = [
                        "sms_sended_conseil",
                        "sms_sended_form",
                        "sms_sended_chatbot",
                        "sms_sended_alert_form",
                        "sms_notification_form",
                        "blocked_by_stop_sms",
                        "email_sended_after_email_response",
                        "received_form_response",
                        "access_to_accessible_account",
                      ];
                      for (const key of Object.keys(listActivities)) {
                        if (
                          displayOnlyUserActions === "false" ||
                          (displayOnlyUserActions === "true" &&
                            !listActivitiesAuto.includes(key))
                        ) {
                          optionsTmp.push({
                            label: listActivities[key],
                            value: key,
                          });
                        }
                      }
                      return optionsTmp.sort((elementA, elementB) => {
                        const first = elementA?.label?.toUpperCase();
                        const second = elementB?.label?.toUpperCase();
                        return first < second ? -1 : first > second ? 1 : 0;
                      });
                    })()}
                    value={activitiesSelected}
                    placeholder="Activités"
                    onChange={(e) => {
                      setActivitiesSelected(e);
                    }}
                  />
                </div>
              </Grid>
              <Grid item display="grid" paddingTop="10px">
                <OrangeCheckBox
                  value={displayOnlyUserActions === "true" ? true : false}
                  onClick={() => {
                    setDisplayOnlyUserActions((x) => {
                      if (x === "true") {
                        localStorage.setItem("displayOnlyUserActions", "false");
                        return "false";
                      }
                      localStorage.setItem("displayOnlyUserActions", "true");
                      return "true";
                    });
                  }}
                  text="Afficher uniquement les actions utilisateur"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid" paddingTop="30px">
            {!isLoading ? (
              <>
                {activities?.length > 0 ? (
                  <div
                    className="table-grid-activity-history"
                    style={{ overflow: "auto" }}
                  >
                    <span className="table-grid-title">Date</span>
                    <span className="table-grid-title">Utilisateur</span>
                    <span className="table-grid-title">Activité</span>
                    <span className="table-grid-title"></span>
                    {Array.isArray(activities) &&
                      activities.map((activity, index) => {
                        if (
                          !Object.keys(listActivities).includes(activity.type)
                        ) {
                          return <></>;
                        }
                        return (
                          <>
                            <span
                              className="table-grid-text"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {formatDateFromTimestamp(activity.date, true)}
                            </span>
                            <span
                              className="table-grid-text"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {(() => {
                                if (!usersList) {
                                  return "";
                                }
                                const userFounded = usersList?.find(
                                  (user) => user?.user_id == activity.user_id
                                );
                                if (userFounded) {
                                  return userFounded?.email;
                                }
                                return "";
                              })()}
                            </span>

                            <span
                              className="table-grid-text"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {formatActivityText(activity)}
                            </span>
                            {[
                              "free_text_schedule",
                              "conseil_schedule",
                              `schedule_chatbot`,
                              "schedule_questionnaire_interactif",
                              "schedule_form",
                              "received_form_response",
                              "access_to_accessible_account",
                            ].includes(activity?.type) ? (
                              <div className="table-grid-text">
                                <Tooltip
                                  title={
                                    isInDisplayActivitiesList(activity)
                                      ? "Cacher les détails"
                                      : "Afficher les détails"
                                  }
                                >
                                  <IconButton
                                    onClick={() => {
                                      setDisplayActivities((dp) => {
                                        const cp = [...dp];
                                        if (
                                          !isInDisplayActivitiesList(activity)
                                        ) {
                                          cp.push(activity);
                                        } else {
                                          cp.splice(
                                            indexFromDisplayActivitiesList(
                                              activity
                                            ),
                                            1
                                          );
                                        }
                                        return cp;
                                      });
                                      // Update
                                    }}
                                    style={{
                                      margin: "0",
                                      padding: "0",
                                    }}
                                  >
                                    <InfoRounded
                                      fontSize="small"
                                      style={{ color: "#0b243c" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ) : (
                              <div className="table-grid-text" />
                            )}
                          </>
                        );
                      })}
                  </div>
                ) : (
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#0b243c",
                      fontFamily: "Poppins",
                    }}
                  >
                    Aucune activité trouvée
                  </span>
                )}
              </>
            ) : (
              <Grid
                container
                direction="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AdminActivityPage: React.FC<any> = (props) => {
  const displayOnlyUserActionsFromLocalStorage = localStorage.getItem(
    "displayOnlyUserActions"
  );
  const { usersList } = useUsersList();
  const [displayOnlyUserActions, setDisplayOnlyUserActions] = React.useState(
    displayOnlyUserActionsFromLocalStorage
      ? displayOnlyUserActionsFromLocalStorage
      : "false"
  );
  const [isFirstRequest, setIsFirstRequest] = React.useState(true);
  const [usersSelected, setUsersSelected] = React.useState([]);
  const [activitiesSelected, setActivitiesSelected] = React.useState([]);
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [activities, setActivities] = React.useState({});

  React.useEffect(() => {
    const interval = setInterval(() => {
      makeAPIRequest(
        "post",
        "/activity/last",
        {
          users: usersSelected.map((u) => u.value),
          activities: activitiesSelected.map((a) => a.value),
          displayOnlyUserActions:
            displayOnlyUserActions === "true" ? true : false,
        },
        "v3"
      )
        .then((res) => {
          setActivities(res?.data);
        })
        .catch((err) => console.error(err));
    }, 60000);
    return () => clearInterval(interval);
  }, [usersSelected, activitiesSelected, displayOnlyUserActions]);

  React.useEffect(() => {
    if (isFirstRequest) {
      setIsLoading(true);
    }
    makeAPIRequest(
      "post",
      "/activity/last",
      {
        users: usersSelected.map((u) => u.value),
        activities: activitiesSelected.map((a) => a.value),
        displayOnlyUserActions:
          displayOnlyUserActions === "true" ? true : false,
      },
      "v3"
    )
      .then((res) => {
        setActivities(res?.data);
        setIsFirstRequest(false);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, [
    usersSelected,
    activitiesSelected,
    isFirstRequest,
    displayOnlyUserActions,
  ]);

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="admin_activity_history" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="admin_activity_history" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
          minWidth={700}
        >
          <AdminActivityBox
            usersSelected={usersSelected}
            setUsersSelected={setUsersSelected}
            usersList={usersList}
            setActivities={setActivities}
            activities={activities}
            activitiesSelected={activitiesSelected}
            setActivitiesSelected={setActivitiesSelected}
            displayOnlyUserActions={displayOnlyUserActions}
            setDisplayOnlyUserActions={setDisplayOnlyUserActions}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default AdminActivityPage;
