import React, { useState, useEffect } from "react";
import TutorialDataService from "../services/TutorialService";
import ITutorialData from "../types/Tutorial";
import { ReactComponent as IconAdd } from "../assets/IconAdd.svg";
import { ReactComponent as IconStar } from "../assets/IconStar.svg";
import { ReactComponent as IconEdit } from "../assets/IconEdit.svg";
import { ReactComponent as IconDuplicate } from "../assets/IconDuplicate.svg";
import { ImCross } from "react-icons/im";
import { AiOutlineStar } from "react-icons/ai";
import EditConseilModal from "./EditConseil";
import useModal from "./UseModal";
import EventEmitter from "../services/event";
import "../styles/last_conseils.scss";
import spec from "../helpers/specialities";
import SelectedAdvice from "../utils/SelectedAdvice";
import { CircularProgress, Grid } from "@mui/material";
import CancellablePopup from "./Library/Popups/CancellablePopup";
import ContentAdviceCardContainer from "./MesConseilsBox/Card/ContentCardAdviceContainer";
import PackAdviceContent, {
  fixURLhttps,
} from "./MesConseilsBox/Card/PackAdviceContent";
import NotSelectedCardBody from "./MesConseilsBox/Card/NotSelectedCardBody";
import * as editAdviceReducer from "../redux/editAdviceReducer";
import { useDispatch } from "react-redux";
import makeArrayCopy from "../utils/makeCopy";
import useSpecialities from "../utils/hooks/useSpecialities";
import * as createAdviceReducer from "../redux/createAdviceReducer";
import { FullConseilCodeQR, getPackPeriod } from "./LastConseilComponent";
import makeAPIRequest from "../utils/makeAPIRequest";
import { openSnackbar } from "../redux/snackbarReducer";
import useAppSelector from "../redux/useAppSelector";
import { removeParenthesis } from "./MesConseilsBox/TabsContainer/AdminTab/AdminTabContent";
import { useOpenSnackbar } from "../pages/CreateOrEditChatbotLexPage";
import isImage from "../utils/handle_files/isImage";

const DisplayFavorite: React.FC<{
  favoriteLoading: any;
  handleFavorite: any;
  tutorial: any;
  tutorials: any;
}> = (props) => {
  const { favoriteLoading, handleFavorite, tutorial, tutorials } = props;
  if (favoriteLoading.find((element) => element === tutorial?.id)) {
    return (
      <CircularProgress
        style={{ color: "#657273", width: "20px", height: "20px" }}
      />
    );
  } else if (tutorials.find((element) => element?.id === tutorial?.id)) {
    return (
      <IconStar
        onClick={() => handleFavorite(tutorial)}
        style={{ cursor: "pointer" }}
      />
    );
  } else {
    return (
      <AiOutlineStar
        onClick={() => handleFavorite(tutorial)}
        style={{ cursor: "pointer" }}
      />
    );
  }
};

export const DisplayConseil: React.FC<{
  tutorial: any;
  idOpen: any;
  openDiv: any;
}> = (props) => {
  const { tutorial, idOpen, openDiv } = props;

  if (tutorial?.message) {
    return (
      <div>
        <p>{tutorial?.message}</p>
        <div className="conseil-card-title">
          Titre : {tutorial?.title}
          {tutorial?.isPack ? " Pack oui" : " Pack non"}
        </div>
      </div>
    );
  } else if (tutorial?.messages[0]) {
    return (
      <section
        style={{ width: "100%", margin: "0", padding: "0" }}
        className={
          "FlexContainer-text" +
          (idOpen[tutorial?.id] == true ? "" : "-collapse")
        }
      >
        {tutorial?.type == "pack" && (
          <div
            className={
              "force_" + (idOpen[tutorial?.id] == true ? "hide" : "display")
            }
          >
            <button
              className="more-button"
              onClick={() => openDiv(tutorial?.id)}
              style={{
                marginLeft: "20px",
                marginTop: idOpen[tutorial?.id] == true ? "0px" : "-15px",
              }}
            >
              {idOpen[tutorial?.id] == true ? "Cacher" : "Afficher"} tous les
              messages
            </button>
          </div>
        )}
        {tutorial?.type == "pack" ? (
          makeArrayCopy(tutorial?.messages?.[0])
            ?.sort((elementA, elementB) => elementA?.hours - elementB?.hours)
            ?.map((n, i) => (
              <div
                style={{ width: "100%" }}
                key={i}
                className={
                  idOpen[tutorial?.id] == true
                    ? "my_margin"
                    : "conseil-card-pack"
                }
              >
                <PackAdviceContent
                  number={i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}
                  attachment={n?.attachment}
                  attachment_name={n?.attachment_name}
                  content={n?.content}
                  hours={n?.hours}
                  url={n?.url}
                  message={n}
                  questionnaire_interactif_id={n?.questionnaire_interactif_id}
                  questionnaire_interactif_title={
                    n?.questionnaire_interactif_title
                  }
                />
              </div>
            ))
        ) : (
          <div className="content_attachment">
            <div
              style={{
                display: "flex",
                height: "100%",
                width: "100%",
                padding: "0",
                margin: "0",
              }}
            >
              {tutorial?.messages[0][0].attachment != null &&
              tutorial?.messages[0][0].attachment != undefined &&
              tutorial?.messages[0][0].attachment != "" &&
              isImage(tutorial.messages[0][0].attachment) ? (
                <a
                  href={fixURLhttps(tutorial?.messages[0][0].attachment)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={fixURLhttps(tutorial?.messages[0][0].attachment)}
                    style={{
                      height: 80,
                      width: 80,
                      minWidth: 80,
                      marginRight: "20px",
                    }}
                    alt=""
                  />
                </a>
              ) : null}
              <ContentAdviceCardContainer
                url={tutorial?.messages?.[0]?.[0]?.url}
                content={tutorial?.messages?.[0]?.[0]?.content}
                questionnaire_interactif_id={
                  tutorial?.messages?.[0]?.[0]?.questionnaire_interactif_id
                }
                questionnaire_interactif_nano_id={
                  tutorial?.messages?.[0]?.[0]?.questionnaire_interactif_nano_id
                }
                questionnaire_interactif_title={
                  tutorial?.messages?.[0]?.[0]?.questionnaire_interactif_title
                }
                is_questionnaire_interactif_owner={
                  tutorial?.messages?.[0]?.[0]
                    ?.is_questionnaire_interactif_owner
                }
                is_form_url_owner={
                  tutorial?.messages?.[0]?.[0]?.is_form_url_owner
                }
                display_help_not_owner={true}
                attachment={fixURLhttps(
                  tutorial?.messages?.[0]?.[0]?.attachment
                )}
              />
            </div>
            {tutorial?.type != "pack"
              ? tutorial?.messages[0].map((n, i) => (
                  <p key={i} className="attachment">
                    {n.attachment != "" && n.attachment != null ? (
                      <p style={{ margin: "0" }}>
                        Piece jointe :{" "}
                        <a
                          href={fixURLhttps(n.attachment)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {n.attachment_name == "" ||
                          n.attachment_name == undefined
                            ? n.attachment.split("/")[
                                n.attachment.split("/").length - 1
                              ]
                            : n.attachment_name}
                        </a>
                      </p>
                    ) : null}
                  </p>
                ))
              : null}
          </div>
        )}
      </section>
    );
  }
};

const ConseilComponent: React.FC<any> = (props) => {
  const selectedAdviceObject = new SelectedAdvice();
  const openSnackbarUse = useOpenSnackbar();
  const [tutorials, setTutorials] = useState([]);
  const [isDuplicatePopUp, setIsDuplicatePopUp] = useState(null);
  const [suggested, setSuggested] = useState([]);
  const [searchIsLoading, setSearchIsLoading] = useState<boolean>(true);
  const { isShowing, toggle } = useModal();
  const [idOpen, setIdOpen] = useState({});
  const [tutorialAdded, setTutorailAdded] = useState<any>({});
  const [lengthArray, setlengthArray] = useState(10);
  const [lastConseilAddClick, setLastConseilAddClick] =
    React.useState(undefined);
  const [cannotUseUrlPopup, setCannotUseUrlPopup] = React.useState("");
  const [
    cannotUseQuestionnaireInteractifPopup,
    setCannotUseQuestionnaireInteractifPopup,
  ] = React.useState("");
  const [, fctMiseAJour] = React.useState({});
  const miseAJour = React.useCallback(() => fctMiseAJour({}), []);
  const dispatch = useDispatch();
  const [proArray] = useSpecialities();
  const { setNumberOfFavorite, setFavoriteLoading, favoriteLoading } = props;
  const specialities = useAppSelector((x) => x.languageReducer.displaySpec);
  const user = useAppSelector((x) => x?.userReducer?.user);

  React.useEffect(() => {
    selectedAdviceObject.clear();
  }, []);

  useEffect(() => {
    if (!specialities) {
      return;
    }
    const onDeleted = (id) => {
      setTutorailAdded({ ...tutorialAdded, [id.text]: false });
      miseAJour();
    };

    const Added = (e) => {
      setTutorailAdded({ ...tutorialAdded, [e.text.id]: true });
      miseAJour();
    };

    const onFavorite = (event) => {
      const tmp = [...favoriteLoading];
      tmp.push(event.id);
      setFavoriteLoading(tmp);
      TutorialDataService.favorite(event.id).then(() => {
        EventEmitter.emit("RefreshFavorite", {
          filterKeySearch: event?.filterKeySearch,
          filterKeyFavourite: event?.filterKeyFavourite,
          filterKey: event?.filterKey,
        });
        onUpdateFavorite();
      });
    };

    const onRefreshAll = () => {
      onUpdateFavorite();
    };

    const onCreate = () => {
      onUpdateFavorite();
    };

    const listenerOnCreate = EventEmitter.addListener("Created", onCreate);
    const listenerRefreshAll = EventEmitter.addListener(
      "RefreshAll",
      onRefreshAll
    );
    const listenerConseilDeleted = EventEmitter.addListener(
      "DeletedConseil",
      onDeleted
    );
    const listenerFavorite = EventEmitter.addListener("Favorite", onFavorite);
    const listenerConseilAdded = EventEmitter.addListener("Added", Added);

    onUpdateFavorite();

    return () => {
      listenerOnCreate.remove();
      listenerRefreshAll.remove();
      listenerFavorite.remove();
      listenerConseilDeleted.remove();
      listenerConseilAdded.remove();
    };
  }, [props?.filterKeyFavourite, specialities]);

  let count = 0;

  const onUpdateFavorite = () => {
    let value = { spec: null, conseil: null, attchment: null };

    if (props?.filterKeyFavourite) value = props?.filterKeyFavourite;
    console.log("ok update");
    console.log(value);

    if (value.spec && value.conseil && value.attchment) {
      Spec_Attchment_Conseil({
        spec: value.spec,
        conseil: value.conseil,
        attchment: value.attchment,
      });
    } else if (value.spec && value.conseil) {
      Spec_Conseil({ spec: value.spec, conseil: value.conseil });
    } else if (value.spec && value.attchment) {
      Spec_Attchment({ spec: value.spec, attchment: value.attchment });
    } else if (value.conseil && value.attchment) {
      Conseil_Attchment({ conseil: value.conseil, attchment: value.attchment });
    } else if (value.spec) {
      Spec({ spec: value.spec });
    } else if (value.conseil) {
      Conseil({ conseil: value.conseil });
    } else if (value.attchment) {
      Attchemnent({ attchment: value.attchment });
    } else {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        setTutorials(response.data.data.default_conseils.favourite_conseils);
        setNumberOfFavorite(
          response.data.data.default_conseils.favourite_conseils.length
        );
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
  };

  const Spec_Attchment_Conseil = (data) => {
    if (data.attchment == "photo") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              (res.type == data.conseil ||
                (data.conseil === "without_smartphone" &&
                  !res.messages[0].find((x) => {
                    if (x?.attachment || x?.url) {
                      return true;
                    }
                    return false;
                  }))) &&
              isValidSpec(res.speciality_id, data.spec) &&
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.url?.toLowerCase()
                  );
                return first || second;
              })?.length > 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
    if (data.attchment == "vidéo") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              (res.type == data.conseil ||
                (data.conseil === "without_smartphone" &&
                  !res.messages[0].find((x) => {
                    if (x?.attachment || x?.url) {
                      return true;
                    }
                    return false;
                  }))) &&
              isValidSpec(res.speciality_id, data.spec) &&
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.url?.toLowerCase()
                  );
                return first || second;
              })?.length > 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
    if (data.attchment == "none") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              (res.type == data.conseil ||
                (data.conseil === "without_smartphone" &&
                  !res.messages[0].find((x) => {
                    if (x?.attachment || x?.url) {
                      return true;
                    }
                    return false;
                  }))) &&
              isValidSpec(res.speciality_id, data.spec) &&
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.url?.toLowerCase()
                  );
                const third =
                  x.attachment &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.attachment?.toLowerCase()
                  );
                const fourth =
                  x.url &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.url?.toLowerCase()
                  );
                return first || second || third || fourth;
              })?.length == 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
  };

  const Spec_Conseil = (data) => {
    setSearchIsLoading(true);
    TutorialDataService.getAll().then((response: any) => {
      setSearchIsLoading(false);
      const tutorialsTmp =
        response.data.data.default_conseils.favourite_conseils.filter(
          (n: any) =>
            (n.type == data.conseil ||
              (data.conseil === "without_smartphone" &&
                !n.messages[0].find((x) => {
                  if (x?.attachment || x?.url) {
                    return true;
                  }
                  return false;
                }))) &&
            isValidSpec(n.speciality_id, data.spec)
        );
      setTutorials(tutorialsTmp);
      setNumberOfFavorite(tutorialsTmp.length);
      setSuggested(response.data.data.default_conseils.suggested_conseils);
      setFavoriteLoading([]);
    });
  };

  const Spec_Attchment = (data) => {
    if (data.attchment == "photo") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              isValidSpec(res.speciality_id, data.spec) &&
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.url?.toLowerCase()
                  );
                return first || second;
              })?.length > 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
    if (data.attchment == "vidéo") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              isValidSpec(res.speciality_id, data.spec) &&
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.url?.toLowerCase()
                  );
                return first || second;
              })?.length > 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
    if (data.attchment == "none") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              isValidSpec(res.speciality_id, data.spec) &&
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.url?.toLowerCase()
                  );
                const third =
                  x.attachment &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.attachment?.toLowerCase()
                  );
                const fourth =
                  x.url &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.url?.toLowerCase()
                  );
                return first || second || third || fourth;
              })?.length == 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
  };

  const Conseil_Attchment = (data) => {
    if (data.attchment == "photo") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              (res.type == data.conseil ||
                (data.conseil === "without_smartphone" &&
                  !res.messages[0].find((x) => {
                    if (x?.attachment || x?.url) {
                      return true;
                    }
                    return false;
                  }))) &&
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.url?.toLowerCase()
                  );
                return first || second;
              })?.length > 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
    if (data.attchment == "vidéo") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              (res.type == data.conseil ||
                (data.conseil === "without_smartphone" &&
                  !res.messages[0].find((x) => {
                    if (x?.attachment || x?.url) {
                      return true;
                    }
                    return false;
                  }))) &&
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.url?.toLowerCase()
                  );
                return first || second;
              })?.length > 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
    if (data.attchment == "none") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              (res.type == data.conseil ||
                (data.conseil === "without_smartphone" &&
                  !res.messages[0].find((x) => {
                    if (x?.attachment || x?.url) {
                      return true;
                    }
                    return false;
                  }))) &&
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.url?.toLowerCase()
                  );
                const third =
                  x.attachment &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.attachment?.toLowerCase()
                  );
                const fourth =
                  x.url &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.url?.toLowerCase()
                  );
                return first || second || third || fourth;
              })?.length == 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
  };

  const isValidSpec = (specId, filterSpec) => {
    console.log(specId);
    console.log(filterSpec);
    if (!specialities) {
      return false;
    }
    const founded = specialities.find((x) => x?.value == filterSpec);
    console.log(founded);
    if (!founded) {
      return false;
    }
    const specIdInt = parseInt(specId);
    if (isNaN(specIdInt)) {
      return false;
    }
    if (founded.specialities.includes(specIdInt)) {
      return true;
    }
    return false;
  };

  const Conseil = (data) => {
    setSearchIsLoading(true);
    TutorialDataService.getAll().then((response: any) => {
      setSearchIsLoading(false);
      const tutorialsTmp =
        response.data.data.default_conseils.favourite_conseils.filter(
          (n: any) =>
            n.type == data.conseil ||
            (data.conseil === "without_smartphone" &&
              !n.messages[0].find((x) => {
                if (x?.attachment || x?.url) {
                  return true;
                }
                return false;
              }))
        );
      setTutorials(tutorialsTmp);
      setNumberOfFavorite(tutorialsTmp.length);
      setSuggested(response.data.data.default_conseils.suggested_conseils);
      setFavoriteLoading([]);
    });
  };

  const Spec = (data) => {
    setSearchIsLoading(true);
    TutorialDataService.getAll().then((response: any) => {
      setSearchIsLoading(false);
      const tutorialsTmp =
        response.data.data.default_conseils.favourite_conseils.filter(
          (n: any) => isValidSpec(n.speciality_id, data.spec)
        );
      setTutorials(tutorialsTmp);
      setNumberOfFavorite(tutorialsTmp.length);
      setSuggested(response.data.data.default_conseils.suggested_conseils);
      setFavoriteLoading([]);
    });
  };
  /*
( LOWER(m.url) like '%.jpg%' OR LOWER(m.url) like '%.jpeg%'
OR LOWER(m.url) like '%.png%' OR LOWER(m.url) like '%.gif%'
OR LOWER(m.url) like '%.svg%' OR LOWER(m.url) like '%.tiff%'
OR LOWER(m.url) like '%.psd%' OR LOWER(m.url) like '%.raw%'
OR LOWER(m.url) like '%.tif%' OR LOWER(m.url) like '%.webp%'
OR LOWER(m.url) like '%.heic%' OR LOWER(m.url) like '%.heif%'
OR LOWER(m.url) like '%.bmp%' ))
                                                                    */
  const Attchemnent = (data) => {
    if (data.attchment == "photo") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.url?.toLowerCase()
                  );
                return first || second;
              })?.length > 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
    if (data.attchment == "vidéo") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.url?.toLowerCase()
                  );
                return first || second;
              })?.length > 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
    if (data.attchment == "none") {
      setSearchIsLoading(true);
      TutorialDataService.getAll().then((response: any) => {
        setSearchIsLoading(false);
        const tutorialsTmp =
          response.data.data.default_conseils.favourite_conseils.filter(
            (res) =>
              res.messages[0].filter((x) => {
                const first =
                  x.attachment &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.attachment?.toLowerCase()
                  );
                const second =
                  x.url &&
                  /.jpg|.jpeg|.png|.gif|.svg|.tiff|.psd|.raw|.tif|.webp|.heic|.heif|.bmp/.test(
                    x.url?.toLowerCase()
                  );
                const third =
                  x.attachment &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.attachment?.toLowerCase()
                  );
                const fourth =
                  x.url &&
                  /.mp4|.mov|.wmv|.flv|.avi|.webm|.avchd|.mxf|.m4v/.test(
                    x.url?.toLowerCase()
                  );
                return first || second || third || fourth;
              })?.length == 0
          );
        setTutorials(tutorialsTmp);
        setNumberOfFavorite(tutorialsTmp.length);
        setSuggested(response.data.data.default_conseils.suggested_conseils);
        setFavoriteLoading([]);
      });
    }
  };

  const openDiv = (id) => {
    if (idOpen[id] == true) setIdOpen({ ...idOpen, [id]: false });
    else setIdOpen({ ...idOpen, [id]: true });
  };

  const countNumber = (max_length: number) => {
    if (count <= max_length) {
      count = count + 1;
      return (
        <div className="count_number">
          {count < 10 ? "0" : ""}
          {count}
        </div>
      );
    }
  };

  const handleAdd = async (tutorial, index) => {
    setLastConseilAddClick(tutorial?.id);
    for (const message of tutorial?.messages?.[0]) {
      if (
        message?.questionnaire_interactif_id &&
        message?.is_questionnaire_interactif_owner === false
      ) {
        setCannotUseQuestionnaireInteractifPopup(
          message.questionnaire_interactif_id
        );
        return;
      }
      if (
        message?.url?.includes("/#/q/") &&
        message?.is_form_url_owner === false
      ) {
        setCannotUseUrlPopup(message?.url);
        return;
      }
    }

    selectedAdviceObject.add(tutorial);
    props.setSelectedAdvice(selectedAdviceObject.getAll());
    EventEmitter.emit("Added", {
      text: tutorial,
    });
    setTutorailAdded({ ...tutorialAdded, [tutorial?.id]: true });
  };

  const handleDelete = (tutorial) => {
    selectedAdviceObject.deleteById(tutorial?.id);
    props.setSelectedAdvice(selectedAdviceObject.getAll());
    EventEmitter.emit("Deleted", {
      text: tutorial,
    });
    EventEmitter.emit("DeletedConseil", {
      text: tutorial,
    });
    setTutorailAdded({ ...tutorialAdded, [tutorial?.id]: false });
  };

  const handleFavorite = async (tutorial) => {
    const tmp = [...favoriteLoading];
    tmp.push(tutorial?.id);
    setFavoriteLoading(tmp);
    await TutorialDataService.favorite(tutorial?.id).then(() => {
      const tutorialsTmp = [...tutorials];
      const suggestedTmp = [...suggested];
      const index = tutorialsTmp?.findIndex(
        (element) => element?.id === tutorial?.id
      );
      if (index !== -1) {
        delete tutorialsTmp?.[index];
        const filterTutorialsTmp = tutorialsTmp.filter(
          (element) => element !== undefined
        );
        setTutorials(filterTutorialsTmp);
        setNumberOfFavorite(filterTutorialsTmp?.length);
      } else {
        const indexSugg = suggestedTmp?.findIndex(
          (element) => element?.id === tutorial?.id
        );
        if (indexSugg != -1) {
          tutorialsTmp?.push(tutorial);
          delete suggestedTmp?.[indexSugg];
          setSuggested(suggestedTmp.filter((element) => element !== undefined));
          const filterTutorialsTmp = tutorialsTmp.filter(
            (element) => element !== undefined
          );
          setTutorials(filterTutorialsTmp);
          setNumberOfFavorite(filterTutorialsTmp?.length);
        }
      }
      setFavoriteLoading([]);
      EventEmitter.emit("RefreshFavorite", {
        filterKeySearch: props?.filterKeySearch,
        filterKey: props?.filterKey,
        filterKeyFavourite: props?.filterKeyFavourite,
      });
    });
  };

  const HandleHours = (tutorial) => {
    return getPackPeriod(tutorial?.messages[0]);
  };

  const tut = (tut) => {
    return tut;
  };

  const confirmDuplicatePopUp = () => {
    if (!isDuplicatePopUp) return;
    setIsDuplicatePopUp(null);
    window.scrollTo({ top: 0, behavior: "smooth" });
    const tutorial = { ...isDuplicatePopUp };
    const data = {
      type: tutorial?.type,
      title: tutorial?.title,
      speciality_id: tutorial?.speciality_id,
      conseil_profession_id: tutorial?.conseil_profession_id,
      add_my_favourites: false,
      messages: [],
    };
    for (const messages of tutorial?.messages) {
      for (const message of messages) {
        data.messages.push({
          attachment: message?.attachment,
          attachment_name: message?.attachment_name,
          url: message?.url,
          short_url: message?.short_url,
          stop_sms: message?.stop_sms,
          content: message?.content,
          hours: message?.hours,
        });
      }
    }
    TutorialDataService.create(data).then((response: any) => {
      EventEmitter.emit("Created", {
        isDuplicate: true,
        filterKeyFavourite: props?.filterKeyFavourite,
        filterKeySearch: props?.filterKeySearch,
        filterKey: props?.filterKey,
        text: tutorial,
      });
    });
  };

  const cancelDuplicatePopUp = () => {
    setIsDuplicatePopUp(null);
  };

  const handleDuplicate = (tutorial) => {
    setIsDuplicatePopUp(tutorial);
  };

  const handleEdit = (tutorial) => {
    if (tutorial.validated != 0) {
      dispatch(createAdviceReducer?.openAdvice({ actualAdvice: tutorial }));
    } else {
      let addToFavorites = false;

      if (
        tutorial?.conseil_favourite_ids?.includes(
          localStorage?.getItem("user_id")
        )
      )
        addToFavorites = true;
      dispatch(
        editAdviceReducer?.openAdvice({
          actualAdvice: tutorial,
          addToFavorites: addToFavorites,
        })
      );
    }
  };

  const increaseArray = () => {
    setlengthArray(lengthArray + 5);
  };

  const deacreseArray = () => {
    setlengthArray(lengthArray - 5);
  };

  const displaySpec = (tutorial) => {
    if (!proArray) return "";
    for (const spec of proArray) {
      if (spec?.value === tutorial?.speciality_id) return spec?.label;
    }
  };

  const updateFavorites = (favoriteIds: any[], beforeUpdate: any[]) => {
    console.log(favoriteIds);
    makeAPIRequest(
      "post",
      `/conseils/update_favorite_order`,
      {
        conseils_favorite: favoriteIds,
      },
      "v3"
    )
      .then((res) => {
        return;
      })
      .catch((err) => {
        setTutorials(beforeUpdate);
        openSnackbarUse.error(
          "Une erreur est survenue lors de la sauvegarde de l'ordre de vos conseils."
        );
      });
  };

  const onMoveUp = (index: number) => {
    if (index <= 0) {
      return;
    }
    const updatedConseils = [...tutorials];
    const beforeUpdate = [...tutorials];
    [updatedConseils[index - 1], updatedConseils[index]] = [
      updatedConseils[index],
      updatedConseils[index - 1],
    ];
    updateFavorites(
      updatedConseils.map((x) => x?.id),
      beforeUpdate
    );
    setTutorials(updatedConseils);
    setTimeout(() => {
      const card = document?.querySelector(`#conseil-card-${index - 1}`);

      card?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 200);
  };

  const onMoveDown = (index: number) => {
    if (index >= tutorials.length - 1) {
      return;
    }
    const updatedConseils = [...tutorials];
    const beforeUpdate = [...tutorials];
    [updatedConseils[index], updatedConseils[index + 1]] = [
      updatedConseils[index + 1],
      updatedConseils[index],
    ];
    updateFavorites(
      updatedConseils.map((x) => x?.id),
      beforeUpdate
    );
    setTutorials(updatedConseils);
    setTimeout(() => {
      const card = document?.querySelector(`#conseil-card-${index + 1}`);

      card?.scrollIntoView({
        block: "center",
      });
    }, 200);
  };

  return (
    <div>
      {cannotUseQuestionnaireInteractifPopup && (
        <CancellablePopup
          firstTitle="Sélection"
          secondTitle="de conseil"
          message="Attention, vous ne pouvez pas envoyer ce questionnaire interactif. Si vous souhaitez le réutiliser, vous devez dupliquer le conseil."
          confirmButtonText="Dupliquer"
          cancelButtonText="Annuler"
          onCancel={() => setCannotUseQuestionnaireInteractifPopup("")}
          onConfirm={() => {
            return;
          }}
        />
      )}
      {cannotUseUrlPopup && (
        <CancellablePopup
          firstTitle="Sélection"
          secondTitle="de conseil"
          message="Attention, vous ne pouvez pas envoyer ce questionnaire. Si vous souhaitez le réutiliser, vous devez dupliquer le conseil."
          confirmButtonText="Dupliquer"
          cancelButtonText="Annuler"
          onCancel={() => setCannotUseUrlPopup("")}
          onConfirm={() => {
            makeAPIRequest(
              "post",
              "/questions/form/create_from_url",
              { url: cannotUseUrlPopup },
              "v3"
            )
              .then((res) => {
                const newFormId = res?.data?.id;
                const toDuplicate = tutorials.find(
                  (x) => x?.id == lastConseilAddClick
                );
                const copyMessages = toDuplicate?.messages?.[0]?.map((x) => {
                  const cp = { ...x };
                  delete cp?.id;
                  if (cp?.url == cannotUseUrlPopup) {
                    cp.url = cp.url.replace(
                      cannotUseUrlPopup?.split("/#/q/")?.[1],
                      newFormId
                    );
                  }
                  return cp;
                });
                const data = {
                  type: toDuplicate?.type,
                  add_my_favourites: true,
                  title: toDuplicate?.title,
                  speciality_id: toDuplicate?.speciality_id,
                  messages: copyMessages,
                };
                TutorialDataService.create(data)
                  .then(() => {
                    EventEmitter.emit("Created", {
                      text: data,
                    });
                    if (data?.add_my_favourites) {
                      EventEmitter.emit("RefreshFavorite");
                    }
                    dispatch(
                      openSnackbar({
                        message:
                          "Le questionnaire a bien été dupliqué, un nouveau conseil a été créé",
                        duration: 5000,
                        type: "success",
                      })
                    );
                    setCannotUseUrlPopup("");
                  })
                  .catch((err) => {
                    dispatch(
                      openSnackbar({
                        message: "Impossible de créer le nouveau conseil",
                        duration: 3000,
                        type: "error",
                      })
                    );
                  });
              })
              .catch((err) => {
                dispatch(
                  openSnackbar({
                    message:
                      err?.response?.data?.error &&
                      err?.response?.data?.error != "Internal Server Error"
                        ? err.response.data.error
                        : `Impossible de trouver ce questionnaire`,
                    duration: 3000,
                    type: "error",
                  })
                );
              });
          }}
        />
      )}
      {isDuplicatePopUp && (
        <CancellablePopup
          firstTitle="Dupliquer"
          secondTitle="ce conseil ?"
          message="Voulez-vous vraiment dupliquer ce conseil ?"
          confirmButtonText="Oui"
          cancelButtonText="Non"
          onCancel={cancelDuplicatePopUp}
          onConfirm={confirmDuplicatePopUp}
        />
      )}
      <div className="col-md-12">
        <span className="title-in-tabs">Conseils favoris</span>
        <ul className="list-group">
          {searchIsLoading && (
            <div
              style={{
                paddingTop: "15px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {tutorials &&
            tutorials?.length > 0 &&
            !searchIsLoading &&
            tutorials
              .filter((tutorial, idx) => idx < lengthArray)
              .map((tutorial, index) => (
                <li
                  id={`conseil-card-${index}`}
                  className={
                    "conseil-card-without-animation"
                    // + (index === currentIndex ? "active" : "")
                  }
                  style={{ paddingTop: 0 }}
                  key={tutorial?.id}
                >
                  <div className="conseil-card-title" style={{}}>
                    {tutorial?.type == "pack" ? (
                      <div
                        style={{
                          display: "flex",
                          background: "#5BB9C1 0% 0% no-repeat padding-box",
                          borderRadius: " 0px 0px 10px 10px",
                          width: "51px",
                          height: "28px",
                        }}
                      >
                        <p style={{ margin: "auto", color: "white" }}>Pack</p>
                      </div>
                    ) : (
                      <div style={{ paddingTop: "10px" }}></div>
                    )}
                    {idOpen[tutorial?.id] == true ? null : (
                      <Grid container direction="row" wrap="nowrap">
                        <Grid item xs display="grid">
                          <div className="title_detail">
                            {!selectedAdviceObject.isSelectedById(
                              tutorial.id
                            ) && (
                              <p
                                style={{ margin: "0" }}
                                className="titre_Conseil"
                              >
                                {tutorial.title}
                                <br />
                              </p>
                            )}
                            <p
                              style={{ margin: "0" }}
                              className="titre_Conseil"
                            >
                              {removeParenthesis(displaySpec(tutorial))}
                            </p>
                            {tutorial.type == "pack" ? (
                              <p style={{ margin: "0", marginLeft: "0" }}>
                                Détail du pack : {tutorial.messages[0].length}{" "}
                                messages
                                {HandleHours(tutorial)}
                              </p>
                            ) : null}
                            {tutorial?.display_name === "1" &&
                              tutorial?.display_name_str && (
                                <p
                                  style={{
                                    margin: "0",
                                    marginLeft: "0",
                                    fontSize: "14px",
                                    color: "#6b6b6b",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Auteur : {tutorial.display_name_str}
                                </p>
                              )}
                            {tutorial.validated == 0
                              ? `en cours de validation`
                              : null}
                          </div>
                        </Grid>
                        <Grid
                          item
                          display="grid"
                          style={{
                            marginLeft: 20,
                            marginRight: 20,
                            marginBottom: 5,
                            marginTop: 5,
                            height: `fit-content`,
                            width: "fit-content",
                            position: "relative",
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="10px"
                          >
                            <Grid item display="grid">
                              <FullConseilCodeQR
                                displayViews={true}
                                conseil={tutorial}
                              />
                            </Grid>
                            {user?.see_credits == 1 && tutorial?.credits && (
                              <Grid item display="grid">
                                <p
                                  style={{
                                    margin: "0",
                                    marginLeft: "0",
                                    fontSize: "12px",
                                    color: "#6b6b6b",
                                  }}
                                >
                                  {tutorial.credits} crédit
                                  {tutorial.credits > 1 ? "s" : ""}
                                </p>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                  {selectedAdviceObject.isSelectedById(tutorial?.id) ===
                  true ? (
                    <div className="conseilAded">
                      <div className="cross">
                        <ImCross
                          onClick={() => handleDelete(tutorial)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        className="vl-l"
                        style={{ width: "0px", margin: "auto" }}
                      ></div>
                      <div style={{ margin: "auto", marginLeft: "25px" }}>
                        <p
                          style={{
                            margin: "0px",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: "medium",
                            color: "#23595D",
                          }}
                        >
                          {tutorial?.title}
                        </p>
                        <p style={{ margin: "0px" }}>
                          {tutorial?.type == "pack" ? (
                            <span>Pack de conseils déjà sélectionné</span>
                          ) : (
                            <span>Conseil déjà sélectionné</span>
                          )}
                        </p>
                      </div>
                      <div
                        className="vl-l"
                        style={{ width: "0px", margin: "auto" }}
                      ></div>
                      <section
                        className="FlexContainer-r"
                        style={{ margin: "auto", marginRight: "-5px" }}
                      >
                        {favoriteLoading.includes(tutorial?.id) ? (
                          <CircularProgress
                            style={{
                              color: "#657273",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        ) : (
                          <IconStar
                            onClick={async () => handleFavorite(tutorial)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        <button className="icon_button modal-toggle">
                          <IconEdit onClick={() => handleEdit(tutorial)} />
                        </button>
                        <EditConseilModal
                          isShowing={isShowing}
                          hide={toggle}
                          id={tutorial?.id}
                          favoriteList={tutorials}
                          dataConseil={tut(tutorials)}
                          {...props}
                        />
                      </section>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {!selectedAdviceObject.isSelectedById(tutorial?.id) &&
                    tutorial && (
                      <NotSelectedCardBody
                        isFirstOnList={index === 0}
                        isLastOnList={index === tutorial?.length - 1}
                        onMoveUp={
                          !props?.filterKeyFavourite?.spec &&
                          !props?.filterKeyFavourite?.conseil &&
                          !props?.filterKeyFavourite?.attchment
                            ? () => onMoveUp(index)
                            : undefined
                        }
                        onMoveDown={
                          !props?.filterKeyFavourite?.spec &&
                          !props?.filterKeyFavourite?.conseil &&
                          !props?.filterKeyFavourite?.attchment
                            ? () => onMoveDown(index)
                            : undefined
                        }
                        tutorial={tutorial}
                        index={index}
                        handleAdd={handleAdd}
                        handleDuplicate={handleDuplicate}
                        handleEdit={handleEdit}
                        idOpen={idOpen}
                        DisplayConseil={
                          <DisplayConseil
                            idOpen={idOpen}
                            tutorial={tutorial}
                            openDiv={openDiv}
                          />
                        }
                        DisplayFavorite={
                          <DisplayFavorite
                            favoriteLoading={favoriteLoading}
                            handleFavorite={handleFavorite}
                            tutorial={tutorial}
                            tutorials={tutorials}
                          />
                        }
                        isShowing={isShowing}
                        favoriteList={tutorials}
                        toggle={toggle}
                        dataConseil={tut(tutorials)}
                        {...props}
                      />
                    )}
                </li>
              ))}
          {tutorials.length > 10 ? (
            lengthArray < tutorials.length ? (
              <button
                className="more-button"
                style={{ marginTop: "25px" }}
                onClick={increaseArray}
              >
                Afficher plus
              </button>
            ) : (
              <button
                className="more-button"
                style={{ marginTop: "25px" }}
                onClick={deacreseArray}
              >
                Afficher moins
              </button>
            )
          ) : null}
          {tutorials.length == 0 && !searchIsLoading && (
            <p>Vous n'avez pas encore ajouté de conseil en favoris</p>
          )}
        </ul>

        <br />
        <div className="horizontal-bar" />
        <br />
      </div>

      <div className="col-md-12">
        <div className="title-in-tabs">Suggestions CertiPair pour vous</div>
        <div className="description-in-tabs">
          Voici des conseils dans vos spécialités qui pourraient vous intéresser
          :
        </div>
        <ul className="list-group">
          {suggested &&
            suggested.map((tutorial, index) => (
              <li
                className={
                  "conseil-card"
                  // + (index === currentIndex ? "active" : "")
                }
                style={{ paddingTop: 0 }}
                key={index}
              >
                <div className="conseil-card-title" style={{}}>
                  {tutorial?.type == "pack" ? (
                    <div
                      style={{
                        display: "flex",
                        background: "#5BB9C1 0% 0% no-repeat padding-box",
                        borderRadius: " 0px 0px 10px 10px",
                        width: "51px",
                        height: "28px",
                      }}
                    >
                      <p style={{ margin: "auto", color: "white" }}>Pack</p>
                    </div>
                  ) : (
                    <div style={{ paddingTop: "10px" }}></div>
                  )}
                  {idOpen[tutorial?.id] == true ? null : (
                    <div className="title_detail">
                      {!selectedAdviceObject.isSelectedById(tutorial?.id) && (
                        <p style={{ margin: "0" }} className="titre_Conseil">
                          {tutorial?.title}
                          <br />
                        </p>
                      )}
                      <p style={{ margin: "0" }} className="titre_Conseil">
                        {removeParenthesis(displaySpec(tutorial))}
                      </p>
                      {tutorial?.type == "pack" ? (
                        <p style={{ margin: "0", marginLeft: "0" }}>
                          Détail du pack : {tutorial?.messages[0].length}{" "}
                          messages
                          {HandleHours(tutorial)}
                        </p>
                      ) : null}
                      {tutorial?.display_name === "1" &&
                        tutorial?.display_name_str && (
                          <p
                            style={{
                              margin: "0",
                              marginLeft: "0",
                              fontSize: "14px",
                              color: "#6b6b6b",
                              fontStyle: "italic",
                            }}
                          >
                            Auteur : {tutorial.display_name_str}
                          </p>
                        )}
                      {tutorial?.validated == 0
                        ? `en cours de validation`
                        : null}
                    </div>
                  )}
                </div>
                {selectedAdviceObject.isSelectedById(tutorial?.id) ? (
                  <div className="conseilAded">
                    <div className="cross">
                      <ImCross
                        onClick={() => handleDelete(tutorial)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      className="vl-l"
                      style={{ width: "0px", margin: "auto" }}
                    ></div>
                    <div style={{ margin: "auto", marginLeft: "25px" }}>
                      <p
                        style={{
                          margin: "0px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: "medium",
                          color: "#23595D",
                        }}
                      >
                        {tutorial?.title}
                      </p>
                      <p style={{ margin: "0px" }}>
                        {tutorial?.type == "pack" ? (
                          <span>Pack de message déjà sélectionné</span>
                        ) : (
                          <span>Message déjà sélectionné</span>
                        )}
                      </p>
                    </div>
                    <div
                      className="vl-l"
                      style={{ width: "0px", margin: "auto" }}
                    ></div>
                    <section
                      className="FlexContainer-r"
                      style={{ margin: "auto", marginRight: "-5px" }}
                    >
                      {favoriteLoading.includes(tutorial?.id) ? (
                        <CircularProgress
                          style={{
                            color: "#657273",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      ) : (
                        <AiOutlineStar
                          onClick={async () => handleFavorite(tutorial)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <button className="icon_button modal-toggle">
                        <IconEdit onClick={() => handleEdit(tutorial)} />
                      </button>
                      <EditConseilModal
                        isShowing={isShowing}
                        hide={toggle}
                        favoriteList={tutorials}
                        id={tutorial?.id}
                        dataConseil={tut(tutorials)}
                        {...props}
                      />
                    </section>
                  </div>
                ) : (
                  <div></div>
                )}
                {!selectedAdviceObject.isSelectedById(tutorial?.id) && (
                  <NotSelectedCardBody
                    tutorial={tutorial}
                    index={index}
                    handleAdd={handleAdd}
                    handleDuplicate={handleDuplicate}
                    handleEdit={handleEdit}
                    idOpen={idOpen}
                    DisplayConseil={
                      <DisplayConseil
                        idOpen={idOpen}
                        tutorial={tutorial}
                        openDiv={openDiv}
                      />
                    }
                    DisplayFavorite={
                      <DisplayFavorite
                        favoriteLoading={favoriteLoading}
                        handleFavorite={handleFavorite}
                        tutorial={tutorial}
                        tutorials={tutorials}
                      />
                    }
                    isShowing={isShowing}
                    favoriteList={tutorials}
                    toggle={toggle}
                    dataConseil={tut(tutorials)}
                    {...props}
                  />
                )}
              </li>
            ))}
        </ul>
        <br />
        <div
          className="horizontal-bar"
          style={{
            width: "100%",
          }}
        />
        <br />

        {/* <button
          className="m-3 btn btn-sm btn-secondary"
          onClick={removeAllTutorials}
        >
          Modifier (Soon)
        </button> */}
      </div>

      {/* <div className="col-md-12">
        {currentTutorial ? (
          <div>
            <h4>Conseil</h4>
            <div>
              <label>
                <strong>Titre:</strong>
              </label>{" "}
              {currentTutorial?.title}
            </div>
            <div>
              <label>
                <strong>Message:</strong>
              </label>{" "}
              {currentTutorial?.message}
            </div>
            <div></div>

            <Link
              to={"/tutorials/" + currentTutorial?.id}
              className="badge badge-warning"
            >
              Edit
            </Link>
          </div>
        ) : (
          <div>
            <br />
            <p>Veuillez cliquer sur un conseil...</p>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ConseilComponent;
