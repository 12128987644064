import ReactGA from 'react-ga4';
import IAdvicesSend from '../types/IAdvicesSend';
import { IUser } from './UserClass';

class GoogleAnalyticsClass {
  trackingId?: string;
  user?: IUser;

  constructor(user?: IUser) {
    this.user = user;
    this.trackingId = process.env.REACT_APP_GA_TRACKING_ID;
    if (this.trackingId) {
      ReactGA.initialize(this.trackingId); // Initialise react-ga4
    }
  }

  public updateUserId() {
    if (this.user) {
      ReactGA.set({ user_id: this.user?.user_login }); // Utilisation de `user_id` pour react-ga4
    } else {
      ReactGA.set({ user_id: null });
    }
  }

  public visitPage(path: string, title: string) {
    this.updateUserId();
    ReactGA.send({ hitType: "pageview", page: path, title: title });
  }

  public trackEvent(category: string, action: string, label?: string) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }

  public duplicateAdviceEvent(type: string, title: string) {
    if (type && title?.length > 0) {
      this.trackEvent(
        'Conseils',
        `Duplication d'un ${type === 'simple' ? 'conseil' : 'pack de conseils'}`,
        title
      );
    }
  }

  public createAdviceEvent(type: string, title: string) {
    if (type && title?.length > 0) {
      this.trackEvent(
        'Conseils',
        `Création d'un ${type === 'simple' ? 'conseil' : 'pack de conseils'}`,
        title
      );
    }
  }

  public editAdviceEvent(type: string, title: string) {
    if (type && title?.length > 0) {
      this.trackEvent(
        'Conseils',
        `Modification d'un ${type === 'simple' ? 'conseil' : 'pack de conseils'}`,
        title
      );
    }
  }

  public updatePatientEvent(firstname: string, lastname: string) {
    if (firstname && lastname) {
      this.trackEvent(
        'Patients',
        `Modification d'un patient`,
        `${firstname} ${lastname}`
      );
    }
  }

  public createPatientEvent(firstname: string, lastname: string) {
    if (firstname && lastname) {
      this.trackEvent(
        'Patients',
        `Création d'un patient`,
        `${firstname} ${lastname}`
      );
    }
  }

  public sendAdvicesEvent(advices: IAdvicesSend) {
    this.updateUserId();
    let label = '';
    const advicesCount = advices.conseil_ids?.length;

    if (advices.hours === "0") {
      if (advicesCount === 1) {
        label = `Envoi d'un conseil au ${advices.target}`;
      } else if (advicesCount > 1) {
        label = `Envoi de ${advicesCount} conseils au ${advices.target}`;
      } else if (advices.freetext != "") {
        label = `Envoi d'un conseil personnalisé au ${advices.target}`;
      }
    } else {
      if (advicesCount === 1) {
        label = `Programmation d'un conseil au ${advices.target} pour dans ${advices.hours}h`;
      } else if (advicesCount > 1) {
        label = `Programmation de ${advicesCount} conseils au ${advices.target} pour dans ${advices.hours}h`;
      } else if (advices.freetext != "") {
        label = `Programmation d'un conseil personnalisé au ${advices.target} pour dans ${advices.hours}h`;
      }
    }

    ReactGA.event({
      category: 'Conseils',
      action: `Envois & programmations d'SMS`,
      label: label,
    });
  }
}

export default GoogleAnalyticsClass;
