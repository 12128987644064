import React, { FunctionComponent, useEffect, useState } from "react";
import TutorialDataService from "../services/TutorialService";
import "../styles/AdminPage.scss";
import axios from "../http-common";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import * as editAdviceReducer from "../redux/editAdviceReducer";
import CreateOrEditAdvice from "./FormPopup/CreateOrEditAdvice";
import useSpecialities from "../utils/hooks/useSpecialities";
import FullPage from "../pages/FullPage";
import {
  ArchiveOutlined,
  ChevronLeftOutlined,
  Close,
  EditOutlined,
  LockRounded,
  VerifiedOutlined,
} from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import BoxTitle from "./Library/Box/BoxTitle";
import { useHistory } from "react-router";
import makeAPIRequest from "../utils/makeAPIRequest";
import useUtils from "../utils/hooks/useUtils";
import { getPackPeriod } from "./LastConseilComponent";
import formatDate from "./Messages/format/formatDate";
import PackAdviceContent, { fixURLhttps } from "./MesConseilsBox/Card/PackAdviceContent";
import ContentAdviceCardContainer from "./MesConseilsBox/Card/ContentCardAdviceContainer";
import makeArrayCopy from "../utils/makeCopy";
import useAppSelector from "../redux/useAppSelector";
import isImage from "../utils/handle_files/isImage";

const Administration: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const isEditAdviceOpen = useAppSelector(
    (state) => state.editAdviceReducer.isAdviceOpen
  );
  const [tutorials, setTutorials] = useState([]);
  const [idOpen, setIdOpen] = useState({});
  const [, fctMiseAJour] = React.useState({});
  const dispatch = useDispatch();
  const [specialites, setSpecialites] = useState<any>();
  const [
    utils,
    setUtils,
    professionOptions,
    civilitiesOptions,
    specialitiesOptions,
    nniTypesOptions,
  ] = useUtils();
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [allPack, setAllPack] = useState(false);
  const miseAJour = React.useCallback(() => fctMiseAJour({}), []);
  const API_URL = process.env.REACT_APP_API_URL;
  // user_name
  let count = 0;
  const [proArray] = useSpecialities();

  useEffect(() => {
    fetch();
    if (JSON.parse(localStorage.getItem("user")).is_admin == "1") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    setIsAdmin(true);
  }, []);

  const getSpecialites = async () => {
    await axios.get(API_URL + "/utils/language").then((res: any) => {
      setIsLoading(false);
      setSpecialites(
        res.data.data.specialities.sort((a, b) => (a.name > b.name ? 1 : -1))
      );
    });
  };

  const fetch = () => {
    setIsLoading(true);
    TutorialDataService.getAdmin().then(async (response: any) => {
      setTutorials(response.data.data.admin_conseils);
      await getSpecialites();
    });
  };

  const tut = (tut) => {
    return tut;
  };

  const openDiv = (id) => {
    if (idOpen[id] == true) setIdOpen({ ...idOpen, [id]: false });
    else setIdOpen({ ...idOpen, [id]: true });
  };

  const handleDelete = (tutorial) => {
    Swal.fire({
      text: "Êtes-vous sûr de vouloir supprimer ce conseil",
      showDenyButton: true,
      confirmButtonText: "Supprimer",
      denyButtonText: `Annuler`,
    }).then((result) => {
      if (result.isConfirmed == true) {
        makeAPIRequest('get', `conseil/${tutorial.id}/delete`).then((res) => {
          let temp = tutorials;
          let index = temp.indexOf(tutorial);
          temp.splice(index, 1);
          setTutorials(temp);
          miseAJour();
          fetch();
        });
      }
    });
  };

  const handleEdit = (tutorial) => {
    let addToFavorites = false;

    dispatch(
      editAdviceReducer?.openAdvice({
        actualAdvice: tutorial,
        addToFavorites: addToFavorites,
      })
    );
  };

  const countNumber = (max_length: number) => {
    if (count <= max_length) {
      count = count + 1;
      return (
        <div className="count_number">
          {count < 10 ? "0" : ""}
          {count}
        </div>
      );
    }
  };

  const handleValidate = (tutorial) => {
    makeAPIRequest('get', `/conseil/${tutorial.id}/validate`).then((tes) => {
      let temp = tutorials;
      let index = temp.indexOf(tutorial);
      temp.splice(index, 1);
      setTutorials(temp);
      miseAJour();
      fetch();
    });
  };

  const handleValidateReserved = (tutorial) => {
    makeAPIRequest('get', `/conseil/${tutorial.id}/validate_reserved`).then((tes) => {
      let temp = tutorials;
      let index = temp.indexOf(tutorial);
      temp.splice(index, 1);
      setTutorials(temp);
      miseAJour();
      fetch();
    });
  }

  const HandleHours = (tutorial) => {
    return (getPackPeriod(tutorial?.messages[0]));
  }

  const setPack = () => {
    let search = {
      search: "",
      speciality: "",
      attachement: "",
      type: "pack",
    };
    setAllPack(true);
    TutorialDataService.search(search).then((response: any) => {
      setTutorials(response.data.data.search_conseils_results.search_conseils);
    });
  };

  // const displayUser = async (tutorial) => {
  //   let name;
  //   await TutorialDataService.get_user(tutorial.user_id).then((res:any) => {
  //     name = res.data.data.user_meta.last_name
  //   })
  //   return(
  //     `${name}`
  //   )
  // };

  const displaySpec = (tutorial) => {
    if (!proArray) return "";
    for (const spec of proArray) {
      if (spec?.value === tutorial?.speciality_id) return spec?.label;
    }
  };

  const displayConseil = (tutorial) => {
    if (tutorial.message) {
      return (
        <div>
          <p>{tutorial.message}</p>
          <div className="conseil-card-title">
            Titre : {tutorial.title}
            {tutorial.isPack ? " Pack oui" : " Pack non"}
          </div>
        </div>
      );
    } else if (tutorial.messages[0]) {
      {
        count = 0;
      }
      return (
        <section
          style={{ width: '100%', margin: '0', padding: '0' }}
          className={
            "FlexContainer-text" +
            (idOpen[tutorial.id] == true ? "" : "-collapse")
          }>
          {tutorial.type == "pack" && 
            <div
              className={"force_" + (idOpen[tutorial.id] == true ? "hide" : "display")}>
              <button
                className="more-button"
                onClick={() => openDiv(tutorial.id)}
                style={{ marginTop: idOpen[tutorial.id] == true ? "0px" : "-15px" }}>
                {idOpen[tutorial.id] == true ? "Cacher" : "Afficher"} tous les messages
              </button>
            </div>
          }
          {tutorial.type == "pack" ? (
            makeArrayCopy(tutorial?.messages?.[0])?.sort((elementA, elementB) => elementA?.hours - elementB?.hours)?.map((n, i) => (
              <div
                style={{ width: '100%' }}
                key={i}
                className={
                  idOpen[tutorial.id] == true
                    ? "my_margin"
                    : "conseil-card-pack"
                }>
                <PackAdviceContent
                  attachment={n?.attachment}
                  attachment_name={n?.attachment_name}
                  content={n?.content}
                  number={i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}
                  url={n?.url}
                  message={n}
                  hours={n?.hours}
                  questionnaire_interactif_id={n?.questionnaire_interactif_id}
                  questionnaire_interactif_title={n?.questionnaire_interactif_title} />
              </div>
            ))
          ) : (
            <div className="content_attachment">
              <div style={{ display: "flex", height: '100%', width: '100%', padding: '0', margin: '0' }}>
                {tutorial.messages[0][0].attachment != null &&
                  tutorial.messages[0][0].attachment != undefined &&
                  tutorial.messages[0][0].attachment != "" &&
                  isImage(tutorial.messages[0][0].attachment) ?
                  <a href={fixURLhttps(tutorial.messages[0][0].attachment)} target="_blank" rel="noreferrer">
                    <img
                      src={fixURLhttps(tutorial.messages[0][0].attachment)}
                      style={{
                        height: 80,
                        width: 80,
                        minWidth: 80,
                        marginRight: "20px",
                      }} alt=""
                    />
                  </a>
                  : null}
                <ContentAdviceCardContainer
                  url={tutorial?.messages?.[0]?.[0]?.url}
                  content={tutorial?.messages?.[0]?.[0]?.content}
                  questionnaire_interactif_id={tutorial?.messages?.[0]?.[0]?.questionnaire_interactif_id}
                  questionnaire_interactif_title={tutorial?.messages?.[0]?.[0]?.questionnaire_interactif_title}
                  attachment={fixURLhttps(tutorial?.messages?.[0]?.[0]?.attachment)} />
              </div>
              {tutorial.type != "pack"
                ? tutorial.messages[0].map((n, i) => (
                  <p
                    key={tutorial?.messages[0]?.id}
                    className="attachment">
                    {n.attachment != "" && n.attachment != null ? (
                      <p style={{ margin: "0" }}>
                        Piece jointe :{" "}
                        <a
                          href={fixURLhttps(n.attachment)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {n.attachment_name == "" ||
                            n.attachment_name == undefined ? (n.attachment.split('/'))[n.attachment.split('/').length - 1] : n.attachment_name}
                        </a>

                      </p>
                    ) : null}
                  </p>
                ))
                : null}
            </div>
          )
          }
        </section >
      );
    }
  };

  const ScheduleCardBoxTop: React.FC<any> = (props) => {
    const { tutorial } = props;
    return (
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid">
          <span style={{
            fontWeight: 800,
            fontSize: 17,
            color: "#000000",
            textAlign: "left",
            fontFamily: "Poppins, sans-serif",
          }}>
            Titre : {tutorial.title}
          </span>
        </Grid>
        <Grid item display="grid">
          <span style={{
            fontWeight: 800,
            fontSize: 17,
            color: "#000000",
            textAlign: "left",
            fontFamily: "Poppins, sans-serif",
          }}>
            {displaySpec(tutorial)}
          </span>
        </Grid>
        {tutorial.type == "pack" && (
          <Grid item display="grid">
            <span style={{
              margin: "0",
              marginLeft: "0",
              fontFamily: "Poppins, sans-serif",
              fontSize: 16,
            }}>
              Détail du pack : {tutorial.messages[0].length}{" "}
              messages {HandleHours(tutorial)}
            </span>
          </Grid>
        )}
        <Grid item display="grid">
          <span style={{
            margin: "0",
            marginLeft: "0",
            fontFamily: "Poppins, sans-serif",
            fontSize: 16,
          }}>
            Auteur du pack : {tutorial.user_name}
          </span>
        </Grid>
        <Grid item display="grid">
          {tutorial.creation_date && (
            <span style={{
              margin: "0",
              marginLeft: "0",
              fontFamily: "Poppins, sans-serif",
              fontSize: 16,
            }}>
              Création : {(formatDate(tutorial.creation_date) as any)?.date} à {(formatDate(tutorial.creation_date) as any)?.hours}
            </span>
          )}
        </Grid>
        {tutorial.validated == 0 &&
          (
            <Grid item display="grid">
              <span style={{
                color: "grey",
                fontFamily: "Poppins, sans-serif",
                fontSize: 13,
              }}>
                en cours de validation
              </span>
            </Grid>
          )
        }
      </Grid>
    );
  };

  const DisplayContent: React.FC<any> = (props) => {
    const { tutorial } = props;
    return (
      <Grid item display='grid' xs
        style={{
          flexWrap: 'nowrap',
          overflowY: tutorial?.type === 'pack' ? 'hidden' : 'auto',
          borderLeft: '2px solid #edf6f7',
          borderRight: '2px solid #edf6f7',
        }}
        height={tutorial?.type === 'pack' && idOpen[tutorial.id] ? '100%' : '108px'}>
        {displayConseil(tutorial)}
      </Grid>
    );
  };

  const DisplayButtonsLeft: React.FC<any> = (props) => {
    const { tutorial } = props;
    return (
      <Grid item display="grid">
        <section className="FlexContainer">
          <Grid
            container
            direction="column"
            alignItems='center'
            justifyContent='center'
            spacing="2px"
            wrap="nowrap">
            <Grid item display="grid">
              <Tooltip title="Valider">
                <IconButton
                  style={{ margin: "0", padding: "0" }}
                  onClick={() => handleValidate(tutorial)}>
                  <VerifiedOutlined
                    fontSize="small"
                    style={{ color: "#0B243C" }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item display="grid">
              <Tooltip title="Mode réservé">
                <IconButton
                  style={{ margin: "0", padding: "0" }}
                  onClick={() => handleValidateReserved(tutorial)}>
                  <LockRounded
                    fontSize="small"
                    style={{ color: "#0B243C" }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item display="grid">
              <Tooltip title="Archiver">
                <IconButton
                  style={{ margin: "0", padding: "0" }}
                  onClick={() => console.log("Archivé")}>
                  <ArchiveOutlined
                    fontSize="small"
                    style={{ color: "#0B243C" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </section>
      </Grid>
    );
  };

  const DisplayButtonsRight: React.FC<any> = (props) => {
    const { tutorial } = props;
    return (
      <Grid item display="grid">
        <section className="FlexContainer">
          <Grid
            container
            direction="column"
            alignItems='center'
            justifyContent='center'
            spacing="2px"
            wrap="nowrap"
          >
            <Grid item display="grid">
              <Tooltip title="Supprimer">
                <IconButton
                  style={{ margin: "0", padding: "0" }}
                  onClick={() => handleDelete(tutorial)}
                >
                  <Close
                    fontSize="small"
                    style={{ color: "#0B243C" }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item display="grid">
              <Tooltip title="Modifier">
                <IconButton
                  style={{ margin: "0", padding: "0" }}
                  onClick={() => handleEdit(tutorial)}
                >
                  <EditOutlined
                    fontSize="small"
                    style={{ color: "#0B243C" }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </section>
      </Grid>
    );
  };

  const ScheduleCardBoxBottom: React.FC<any> = (props) => {
    const { tutorial } = props;
    return (
      <Grid container direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        paddingBottom="10px">
        <DisplayButtonsLeft tutorial={tutorial} />
        <DisplayContent tutorial={tutorial} />
        <DisplayButtonsRight tutorial={tutorial} />
      </Grid>
    );
  };

  const ScheduleCardBoxHeader: React.FC<any> = (props) => {
    const { tutorial } = props;
    if (tutorial?.type == "pack") {
      return (
        <div
          style={{
            display: "flex",
            background: "#5BB9C1 0% 0% no-repeat padding-box",
            borderRadius: " 0px 0px 10px 10px",
            width: "51px",
            height: "28px",
          }}>
          <p style={{ margin: "auto", color: "white" }}>
            Pack
          </p>
        </div>
      )
    } else {
      return (
        <div style={{ paddingTop: "10px" }} />
      )
    }
  };

  const ScheduleCardBox: React.FC<any> = (props) => {
    const { tutorial, index } = props;
    return (
      <Grid container direction="column" wrap="nowrap" className={"conseil-card"} style={{ paddingTop: 0, paddingLeft: "25px", paddingRight: "25px" }}>
        <Grid item display='grid'>
          <ScheduleCardBoxHeader tutorial={tutorial} />
        </Grid>
        {!idOpen[tutorial.id] &&
          <Grid item display='grid'>
            <ScheduleCardBoxTop tutorial={tutorial} />
          </Grid>
        }
        <Grid item display='grid' paddingTop="20px" />
        <Grid item display='grid'>
          <ScheduleCardBoxBottom tutorial={tutorial} />
        </Grid>
      </Grid>
    )
  };

  const ConseilListBox = () => {
    return (
      <Grid container direction="column" wrap="nowrap">
        {tutorials.filter((e) => e.validated == 0).map((tutorial, index) => {
          return (
            <Grid item display="grid" key={index}>
              <ScheduleCardBox tutorial={tutorial} index={index} />
            </Grid>
          )
        })
        }
      </Grid>
    );
  };

  return (
    <>
      {isEditAdviceOpen && (
        <CreateOrEditAdvice isEdit={true} isCreate={false} specialitiesOptions={specialitiesOptions} />
      )}
      <FullPage>
        <Grid container direction="column" width="80vw" wrap="nowrap">
          <Grid item display="grid" paddingBottom="10px">
            <button
              className={`return-button`}
              style={{ width: "100px", height: "30px", fontSize: "16px" }}
              onClick={() => history.push("/profile")}
            >
              <span style={{ display: "flex" }}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item display="grid" marginTop="1px">
                    <ChevronLeftOutlined />
                  </Grid>
                  <Grid item display="grid">
                    Retour
                  </Grid>
                </Grid>
              </span>
            </button>
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              direction="column"
              wrap="nowrap"
              display="flex"
              paddingLeft="41px"
              paddingTop="26px"
              paddingRight="41px"
              paddingBottom="60px"
              minWidth="320px"
              style={{
                background:
                  "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
                borderRadius: "24px",
              }}
            >
              <Grid item display="grid">
                <Grid container display="flex" direction="column"  wrap="nowrap">
                  <Grid item display="grid">
                    <BoxTitle first="Gestion" second="des conseils" />
                  </Grid>
                  <Grid item display="grid" paddingTop="30px">
                    {isLoading ? (
                      <Grid
                        container
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <ConseilListBox />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FullPage>
    </>
  );
};

export default Administration;
