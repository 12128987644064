import { Grid } from "@mui/material";
import * as React from "react";
import {
  MessageConseil,
  calcCostMessage,
  getUnicodeCharIfUnicode,
  isMessageUnicode,
} from "../TabsContainer/AdminTab/AdminTabContent";
import { transformUrlToAbsolute } from "../../../pages/LinksPage";
import useAppSelector from "../../../redux/useAppSelector";

interface IPackAdviceContentProps {
  url?: string;
  attachment?: string;
  content?: string;
  attachment_name?: string;
  number?: string;
  hours?: any;
  questionnaire_interactif_id?: string;
  questionnaire_interactif_nano_id?: string;
  questionnaire_interactif_title?: string;
  message?: MessageConseil;
  displayCost?: boolean;
}

export const fixURLhttps = (url: string) => {
  if (!url) {
    return url;
  }
  if (url.startsWith("prod2-app") || url.startsWith("staging2-app")) {
    return `https://${url}`;
  }
  if (
    url.startsWith("http://prod2-app") ||
    url.startsWith("http://staging2-app")
  ) {
    return url.replace("http", "https");
  }
  return transformUrlToAbsolute(url);
};

export const formatHours = (hours) => {
  let isNegative = false;
  if (hours < 0) isNegative = true;
  let hour = isNegative ? hours * -1 : hours;
  let day = 0;
  let month = 0;
  let year = 0;
  let format = "";
  const tmp = [];

  while (hour >= 24) {
    hour -= 24;
    day += 1;
  }
  while (day >= 30) {
    day -= 30;
    month += 1;
  }
  while (month >= 12) {
    month -= 12;
    year += 1;
  }
  if (year != 0) tmp.push(`${year} an${year > 1 ? "s" : ""}`);
  if (month != 0) tmp.push(`${month} mois`);
  if (day != 0) tmp.push(`${day} jour${day > 1 ? "s" : ""}`);
  if (hour != 0) tmp.push(`${hour} heure${hour > 1 ? "s" : ""}`);

  if (tmp.length === 1) format = `${isNegative ? "-" : ""}${tmp[0]}`;
  if (tmp.length === 2)
    format = `${isNegative ? "-" : ""}${tmp[0]} et ${tmp[1]}`;
  if (tmp.length === 3)
    format = `${isNegative ? "-" : ""}${tmp[0]}, ${tmp[1]} et ${tmp[2]}`;
  if (tmp.length === 4)
    format = `${isNegative ? "-" : ""}${tmp[0]}, ${tmp[1]}, ${tmp[2]} et ${
      tmp[3]
    }`;

  if (hours === 0) return "0 heure";
  return format;
};

export const getClassicOrUnicode = (message: MessageConseil): string => {
  const content = message?.content ? message.content : "";

  const charUnicode = getUnicodeCharIfUnicode(content);

  if (charUnicode) {
    return `SMS unicode à cause du caractère '${charUnicode}'`;
  }

  return `SMS classique`;
};

const PackAdviceContent = (props: IPackAdviceContentProps) => {
  const {
    url,
    attachment,
    attachment_name,
    content,
    number,
    hours,
    questionnaire_interactif_id,
    questionnaire_interactif_title,
    message,
    displayCost,
  } = props;

  const user = useAppSelector((x) => x?.userReducer?.user);

  const formatHours = () => {
    let isNegative = false;
    if (hours < 0) isNegative = true;
    let hour = isNegative ? hours * -1 : hours;
    let day = 0;
    let month = 0;
    let year = 0;
    let format = "";
    const tmp = [];

    while (hour >= 24) {
      hour -= 24;
      day += 1;
    }
    while (day >= 30) {
      day -= 30;
      month += 1;
    }
    while (month >= 12) {
      month -= 12;
      year += 1;
    }
    if (year != 0) tmp.push(`${year} an${year > 1 ? "s" : ""}`);
    if (month != 0) tmp.push(`${month} mois`);
    if (day != 0) tmp.push(`${day} jour${day > 1 ? "s" : ""}`);
    if (hour != 0) tmp.push(`${hour} heure${hour > 1 ? "s" : ""}`);

    if (tmp.length === 1) format = `${isNegative ? "-" : ""}${tmp[0]}`;
    if (tmp.length === 2)
      format = `${isNegative ? "-" : ""}${tmp[0]} et ${tmp[1]}`;
    if (tmp.length === 3)
      format = `${isNegative ? "-" : ""}${tmp[0]}, ${tmp[1]} et ${tmp[2]}`;
    if (tmp.length === 4)
      format = `${isNegative ? "-" : ""}${tmp[0]}, ${tmp[1]}, ${tmp[2]} et ${
        tmp[3]
      }`;

    if (hours === 0) return "0 heure";
    return format;
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      style={{ paddingLeft: "30px", paddingRight: "30px" }}
      paddingTop="15px"
    >
      <Grid item display="grid">
        <Grid container direction="row" display="flex">
          <Grid item display="grid" alignItems="center" marginRight="20px">
            <span
              style={{
                textAlign: "left",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontWeight: "bold",
                color: "#5bb9c1",
              }}
            >
              {number}
            </span>
          </Grid>
          {attachment &&
            attachment !== "" &&
            (attachment.includes("png") ||
              attachment.includes("jpg") ||
              attachment.includes("jpeg")) && (
              <Grid item display="grid" alignItems="center" marginRight="15px">
                <a
                  href={fixURLhttps(attachment)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={fixURLhttps(attachment)}
                    style={{
                      height: "70px",
                      width: "70px",
                      minWidth: "70px",
                      objectFit: "cover",
                    }}
                    alt="img"
                  />
                </a>
              </Grid>
            )}
          <Grid
            item
            display="grid"
            xs
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            <span
              style={{
                textAlign: "left",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "normal",
                color: "#0B243C",
              }}
            >
              {content}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        display="grid"
        marginTop="5px"
        marginBottom="5px"
        style={{ fontFamily: "Poppins,", color: "#0B243C", fontSize: "16px" }}
      >
        <Grid container direction="column" wrap="nowrap">
          {attachment != "" && attachment != null && (
            <Grid item display="grid" marginTop="5px">
              <span style={{ margin: "0", padding: "0", fontSize: "13px" }}>
                Piece jointe :{" "}
                <a
                  href={fixURLhttps(attachment)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {attachment_name == ""
                    ? attachment.split("/")[attachment.split("/").length - 1]
                    : attachment_name}
                </a>
              </span>
            </Grid>
          )}
          {!(attachment && attachment !== "") && url && url?.length > 0 && (
            <Grid item display="grid" marginTop="5px">
              <span style={{ margin: "0", padding: "0", fontSize: "13px", wordBreak: "break-all" }}>
                Lien web :{" "}
                <a href={transformUrlToAbsolute(url)} target="_blank" rel="noreferrer">
                  {url}
                </a>
              </span>
            </Grid>
          )}
          {questionnaire_interactif_title && questionnaire_interactif_id && (
            <Grid item display="grid" marginTop="5px">
              <span style={{ margin: "0", padding: "0", fontSize: "13px" }}>
                Questionnaire interactif : {questionnaire_interactif_title}
              </span>
            </Grid>
          )}
          <Grid item display="grid" justifyContent="flex-end">
            <span
              style={{
                textAlign: "right",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "normal",
                color: "#0B243C",
              }}
            >
              {`Période d'envoi : ${formatHours()}`}
            </span>
          </Grid>
          {message?.credits && user?.see_credits == 1 && <Grid item display="grid" justifyContent="flex-end">
            <span
              style={{
                textAlign: "right",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "normal",
                color: "#6b6b6b",
              }}
            >
              {`${message?.credits} crédit${message?.credits > 1 ? 's' : ''}`}
            </span>
          </Grid>}
          {displayCost && (
            <Grid item display="grid" justifyContent="flex-end">
              <span
                style={{
                  textAlign: "right",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "#0B243C",
                }}
              >
                {[
                  {
                    stop: calcCostMessage(message, true),
                    noStop: calcCostMessage(message, false),
                  },
                ].map((cost, index) => {
                  if (cost.stop === cost.noStop) {
                    return (
                      <span key={index}>
                        Coût : {(cost.stop * 0.0414).toFixed(3)}€ TTC /{" "}
                        {(cost.stop * 0.0345).toFixed(3)}€ HT
                      </span>
                    );
                  }
                  return (
                    <span key={index}>
                      Coût : {(cost.noStop * 0.0414).toFixed(3)}€ TTC /{" "}
                      {(cost.noStop * 0.0345).toFixed(3)}€ HT à{" "}
                      {(cost.stop * 0.0414).toFixed(3)}€ TTC /{" "}
                      {(cost.stop * 0.0345).toFixed(3)}€ HT
                    </span>
                  );
                })}
              </span>
            </Grid>
          )}
          {displayCost && (
            <Grid item display="grid" justifyContent="flex-end">
              <span
                style={{
                  textAlign: "right",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "#0B243C",
                }}
              >
                {getClassicOrUnicode(message)}
              </span>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

PackAdviceContent.default = {
  url: null,
  attachment: null,
  attachment_name: null,
  content: null,
  number: null,
  hours: null,
};

export default PackAdviceContent;
